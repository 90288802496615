/* eslint-disable no-plusplus */
import React, { useEffect, useMemo, useState } from 'react';
import { Cross } from '@saleshandy/icons';
import { find, some } from 'lodash';
import type { IProps } from './create-email-accounts-tab-container';
import Icon from '../../../../../../shared/design-system/components/atoms/icon/icon';
import { OverlayTooltip } from '../../../../../../shared/design-system/components/overlay/tooltip';
import SuggestedDomainsEmailsTable from '../suggested-domains-emails-table';
import { EmailAccountInfrastructurePlanType } from '../../../../enums/create-email-infrastructure';
import { EmailAccountType } from '../../../../types/create-email-infrastructure';
import CreateEmailAccountForm from '../create-email-account-form';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import UploadProfileImageModal from '../../../../modals/upload-profile-image-modal';
import { getIsRequestPending } from '../../../../../../shared/utils/get-request-status';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
} from '../../../../../../shared/utils';
import toaster from '../../../../../../shared/toaster';
import NudgeUserToUploadProfileImageModal from '../../../../modals/nudge-user-to-upload-profile-image-modal';
import { NoProfileImageSVG } from '../../../../../../shared/svg/no-profile-image';

const CreateEmailAccountsTab: React.FC<IProps> = ({
  domainPlans,
  addEmailAccountToCart,
  removeEmailAccountFromCart,
  emailAccountInfrastructureCart,

  sendUploadDomainProfilePictureRequest,
  updateEmailAccountProfile,
  resetUploadDomainProfilePictureRequest,
  uploadDomainProfilePictureRequestStatus,
  uploadDomainProfilePictureRequestError,
  uploadDomainProfilePictureResponse,

  nudgeUserToUploadProfileImageModal,
  setNudgeUserToUploadProfileImageModal,
  handleNextButtonClickOnCreateEmailAccounts,
}) => {
  const [
    uploadProfileImageForAllEmailAccounts,
    setUploadProfileImageForAllEmailAccounts,
  ] = useState(true);
  const [
    showUploadProfileImageModal,
    setShowUploadProfileImageModal,
  ] = useState(false);

  const emailsPrice = useMemo(() => {
    const plan = find(domainPlans, {
      planType: EmailAccountInfrastructurePlanType.InboxSetup,
    });
    const amount = plan?.amount ? parseFloat(plan.amount) : 0;
    return (amount / 3).toString();
  }, [domainPlans]);

  const handleAddEmailAccountToTableAndCart = (
    emailAccounts: EmailAccountType[],
  ) => {
    const existingEmails = new Set(
      emailAccountInfrastructureCart.emailAccounts.map((email) => email.email),
    );

    const domainCounts: Record<string, number> = {};

    const filteredEmailAccounts = emailAccounts.filter((emailAccount) => {
      const emailDomain = emailAccount.email.split('@')[1];

      if (existingEmails.has(emailAccount.email)) {
        return false;
      }

      if (!domainCounts[emailDomain]) {
        domainCounts[
          emailDomain
        ] = emailAccountInfrastructureCart.emailAccounts.filter(
          (existingEmail) => existingEmail.email.endsWith(`@${emailDomain}`),
        ).length;
      }

      if (domainCounts[emailDomain] >= 5) {
        return false;
      }

      existingEmails.add(emailAccount.email);
      domainCounts[emailDomain]++;
      return true;
    });

    filteredEmailAccounts.forEach((emailAcc) =>
      addEmailAccountToCart(emailAcc),
    );
  };

  const handleToggleEmailAccountSelection = (
    firstName: string,
    lastName: string,
    email: string,
    price: number,
    isSelected: boolean,
  ) => {
    if (isSelected) {
      removeEmailAccountFromCart(email);
    } else {
      addEmailAccountToCart({
        firstName,
        lastName,
        price,
        email,
        profile: uploadDomainProfilePictureResponse,
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        key: 'email',
        header: 'Email Accounts',
        component: ({ email, profile }) => (
          <div className="email-with-icon">
            {profile === null ? (
              <NoProfileImageSVG />
            ) : (
              <img
                className="email-accounts-profile-image"
                src={profile.url}
                alt="Profile"
              />
            )}
            <span>{email}</span>
          </div>
        ),
      },
      {
        key: 'price',
        header: 'Price per Month',
        component: ({ price }) => <span>${price}</span>,
      },
      {
        key: 'action',
        header: '',
        component: ({ email, isSelected, price, firstName, lastName }) => (
          <>
            {isSelected ? (
              <OverlayTooltip text="Remove from Cart" key={`${email}-remove`}>
                <Cross
                  key={`${email}-remove`}
                  className="remove-email-from-cart-icon"
                  color="gray"
                  width={20}
                  height={20}
                  onClick={() =>
                    handleToggleEmailAccountSelection(
                      firstName,
                      lastName,
                      email,
                      price,
                      isSelected,
                    )
                  }
                />
              </OverlayTooltip>
            ) : (
              <OverlayTooltip text="Add to Cart" key={`${email}-add`}>
                <Icon
                  key={`${email}-add`}
                  identifier="math-plus"
                  className="add-to-cart-icon"
                  width={20}
                  height={20}
                  onClick={() =>
                    handleToggleEmailAccountSelection(
                      firstName,
                      lastName,
                      email,
                      price,
                      isSelected,
                    )
                  }
                />
              </OverlayTooltip>
            )}
          </>
        ),
      },
    ],
    [handleToggleEmailAccountSelection],
  );

  const data = useMemo(
    () =>
      emailAccountInfrastructureCart.emailAccounts.map((e) => ({
        email: e.email,
        price: emailsPrice,
        isSelected: some(emailAccountInfrastructureCart.emailAccounts, {
          email: e.email,
        }),
        firstName: e.firstName,
        lastName: e.lastName,
        profile: e.profile,
      })),
    [emailAccountInfrastructureCart.emailAccounts],
  );

  const onShowUploadProfileImageModal = (forAll = true) => {
    setShowUploadProfileImageModal(true);
    setUploadProfileImageForAllEmailAccounts(forAll);
  };

  const onCloseUploadProfileImageModal = () => {
    setShowUploadProfileImageModal(false);
    setUploadProfileImageForAllEmailAccounts(true);
  };

  const allEmailsHaveProfileURL = useMemo(
    () => data.every((email) => email.profile),
    [data],
  );

  const getActionComponent = () => (
    <div
      className="upload-profile-image-link"
      {...(allEmailsHaveProfileURL
        ? {}
        : accessibleOnClick(onShowUploadProfileImageModal))}
    >
      <Icon identifier="software-upload" className="upload-icon" />
      <span className="">Upload Profile Image</span>
    </div>
  );

  const handleUploadProfileClick = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    sendUploadDomainProfilePictureRequest(formData);
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: uploadDomainProfilePictureRequestStatus,
      onSuccess: () => {
        if (uploadProfileImageForAllEmailAccounts) {
          updateEmailAccountProfile(uploadDomainProfilePictureResponse);
        }
        setShowUploadProfileImageModal(false);
        resetUploadDomainProfilePictureRequest();
        toaster.success('Profile image added successfully');
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: uploadDomainProfilePictureRequestError,
          onError: () => {
            toaster.error(uploadDomainProfilePictureRequestError.message);
          },
        });
        resetUploadDomainProfilePictureRequest();
      },
    });
  }, [uploadDomainProfilePictureRequestStatus]);

  return (
    <>
      <div className="create-emails-container">
        <CreateEmailAccountForm
          handleAddEmailAccountToTableAndCart={
            handleAddEmailAccountToTableAndCart
          }
          domainsInCart={emailAccountInfrastructureCart.domains}
          emailsPrice={emailsPrice}
          uploadedProfile={uploadDomainProfilePictureResponse}
          uploadProfileImageForAllEmailAccounts={
            uploadProfileImageForAllEmailAccounts
          }
          onShowUploadProfileImageModal={onShowUploadProfileImageModal}
        />

        <SuggestedDomainsEmailsTable
          columns={columns}
          data={data}
          tableTitle={
            <div className="emails-header">
              <p>Email Accounts in Cart </p>
              <div className="emails-count">
                <span>
                  {emailAccountInfrastructureCart.emailAccounts.length}
                </span>
              </div>
            </div>
          }
          actionComponent={getActionComponent()}
        />
      </div>
      <UploadProfileImageModal
        show={showUploadProfileImageModal}
        onSubmit={handleUploadProfileClick}
        onClose={onCloseUploadProfileImageModal}
        isUploading={getIsRequestPending(
          uploadDomainProfilePictureRequestStatus,
        )}
      />
      <NudgeUserToUploadProfileImageModal
        show={nudgeUserToUploadProfileImageModal}
        onContinueAnyway={handleNextButtonClickOnCreateEmailAccounts}
        onUploadImage={() => {
          setNudgeUserToUploadProfileImageModal(false);
          onShowUploadProfileImageModal();
        }}
        onHide={() => {
          setNudgeUserToUploadProfileImageModal(false);
        }}
      />
    </>
  );
};

export default CreateEmailAccountsTab;
