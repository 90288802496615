import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { RouteParams, SendingSettingsProps } from '../types';
import toaster, { Theme } from '../../../../../../../shared/toaster';
import Button from '../../../../../../../shared/design-system/components/atoms/button';
import { executeOnRequestStatus } from '../../../../../../../shared/utils/execute-on-request-status';

import EmailDailySendingLimit from '../../email-daily-sending-limit';
import DailyRampUpToggle from './sub-components/daily-ramp-up-toggle';
import DailyRampUpContainer from './sub-components/daily-ramp-up-contanier';
import DefaultRampUpSettings from '../../../../../enums/default-rampup-settings';

import SaveRampUpConfirmation from './sub-components/save-ramp-up-confirmation';
import { getIsRequestPending, showGeneralErrorMessage } from '../helpers';
import { redirectWithoutRefresh } from '../../../../../../../shared/utils';
import { Status } from '../../../../../../../shared/enums/status';
import { EmailAccountType } from '../../../../../enums/email-account';
import TimeIntervalSetting from './sub-components/time-interval-setting';

const SendingSettings: React.FC<SendingSettingsProps> = ({
  emailAccountId,
  emailAccountStatus,
  emailAccountType,
  rampUpSettings,
  onSave,
  rampUpSettingsRequestStatus,
  rampUpSettingsRequestMessage,
  rampUpSettingsRequestError,
  sendGetEmailAccountSettingsRequest,
  sendEmailTimeIntervalRequest,
  resetEmailAccountRampUpSettingsRequestState,
  agencyConfig,
  emailTimeIntervalSettingsRequestStatus,
  emailTimeIntervalSettingsRequestMessage,
  sendGetEmailAccountSetupScoreRequest,
  featureUsages,
}) => {
  const {
    rampUpStatus,
    initialSendingLimit,
    rampUpPercent,
    rampUpLimitReached,
  } = rampUpSettings;

  const { t } = useTranslation();

  const params = useParams<RouteParams>();
  const { hashId } = params;

  const [saveConfirmationModal, setSaveConfirmationModal] = useState<boolean>(
    false,
  );
  const [confirmationModalText, setConfirmationModalText] = useState('');

  // RamUp Settings State
  const [dailyRampUp, setDailyRampUp] = useState<boolean>(
    Boolean(rampUpStatus),
  );
  const [emailsPerDay, setEmailsPerDay] = useState<number>(initialSendingLimit);
  const [emailsPerDayPercent, setEmailsPerDayPercent] = useState<number>(
    rampUpPercent,
  );
  const [dirty, setDirty] = useState({
    dailyRampUp: false,
    emailsPerDay: false,
    emailsPerDayPercent: false,
  });

  // Error States
  const [emailsPerDayError, setEmailsPerDayError] = useState<string>('');
  const [
    emailsPerDayPercentError,
    setEmailsPerDayPercentError,
  ] = useState<string>('');

  const onEmailsPerDayChange = (value) => {
    if (Number(value) === 0) {
      setEmailsPerDay(1);
    } else {
      if (value > Number(rampUpSettings.maxSendingLimit)) {
        setEmailsPerDayError(t('messages.emails_per_day_error'));
      } else {
        setEmailsPerDayError('');
      }
      setEmailsPerDay(value);

      if (!dirty.emailsPerDay) {
        setDirty({ ...dirty, emailsPerDay: true });
      }
    }
  };

  const onEmailsPerDayPercentChange = (value) => {
    if (Number(value) === 0 || Number(value) > 100) {
      setEmailsPerDayPercentError(t('messages.emails_per_day_percent_error'));
    } else if (emailsPerDayPercentError) {
      setEmailsPerDayPercentError('');
    }
    setEmailsPerDayPercent(value);

    if (!dirty.emailsPerDayPercent) {
      setDirty({ ...dirty, emailsPerDayPercent: true });
    }
  };

  const onKeyPress = (e) => {
    if (e.key === '.' || e.key === '+' || e.key === '-') {
      e.preventDefault();
    }
  };

  const onDropAndPaste = (e) => {
    e.preventDefault();
  };

  const hideSaveConfirmationModal = () => {
    setSaveConfirmationModal(false);
  };

  const updateRampUpSettings = (payloadObj = null) => {
    if (payloadObj !== null) {
      onSave(payloadObj);
      return;
    }

    if (emailsPerDayError || emailsPerDayPercentError) {
      return;
    }

    const payload = {
      emailAccountId,
      rampUpStatus: dailyRampUp,
      rampUpInitialSendingLimit: emailsPerDay,
      rampUpPercent: emailsPerDayPercent,
    };
    onSave(payload);
  };

  const onDailyRampUpChange = (value) => {
    setDailyRampUp(value);
    if (!value) {
      setEmailsPerDay(DefaultRampUpSettings.InitialSendingLimit);
      setEmailsPerDayPercent(DefaultRampUpSettings.RampUpPercent);
      setEmailsPerDayError('');
      setEmailsPerDayPercentError('');
      if (rampUpSettings.rampUpStatus) {
        const payload = {
          emailAccountId,
          rampUpStatus: value,
          rampUpInitialSendingLimit: DefaultRampUpSettings.InitialSendingLimit,
          rampUpPercent: DefaultRampUpSettings.RampUpPercent,
        };
        updateRampUpSettings(payload);
      }
    }

    if (!dirty.dailyRampUp) {
      setDirty({ ...dirty, dailyRampUp: true });
    }
  };

  const onSaveHandler = (e) => {
    e.preventDefault();

    if (
      Boolean(rampUpSettings.rampUpStatus) === dailyRampUp &&
      dirty.emailsPerDay
    ) {
      setSaveConfirmationModal(true);
      setConfirmationModalText('today');
      return;
    }

    if (
      Boolean(rampUpSettings.rampUpStatus) === dailyRampUp &&
      dirty.emailsPerDayPercent
    ) {
      setSaveConfirmationModal(true);
      setConfirmationModalText('tomorrow');
      return;
    }

    updateRampUpSettings();
  };

  const resetCompoenentState = () => {
    setSaveConfirmationModal(false);
    setConfirmationModalText('');

    setDailyRampUp(Boolean(rampUpStatus));
    setEmailsPerDay(initialSendingLimit);
    setEmailsPerDayPercent(rampUpPercent);
    setDirty({
      dailyRampUp: false,
      emailsPerDay: false,
      emailsPerDayPercent: false,
    });

    setEmailsPerDayError('');
    setEmailsPerDayPercentError('');
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: rampUpSettingsRequestStatus,
      onSuccess: () => {
        toaster.success(
          t('messages.daily_ramp_up_settings_updated_successfully'),
          {
            theme: Theme.New,
          },
        );
        sendGetEmailAccountSettingsRequest(hashId);
        hideSaveConfirmationModal();
        resetEmailAccountRampUpSettingsRequestState();
      },
      onFailed: () => {
        if (rampUpSettingsRequestError) {
          showGeneralErrorMessage(rampUpSettingsRequestMessage);
        }
      },
    });
  }, [rampUpSettingsRequestStatus]);

  useEffect(
    () => () => {
      resetCompoenentState();
    },
    [],
  );

  useEffect(() => {
    if (
      emailAccountType === EmailAccountType.SmtpImap &&
      emailAccountStatus === Status.Inactive
    ) {
      redirectWithoutRefresh('/email-accounts');
    }
  }, [emailAccountStatus, emailAccountType]);

  useEffect(() => {
    executeOnRequestStatus({
      status: emailTimeIntervalSettingsRequestStatus,
      onSuccess: () => {
        toaster.success(emailTimeIntervalSettingsRequestMessage, {
          theme: Theme.New,
        });
        sendGetEmailAccountSetupScoreRequest({
          emailAccountId,
          refresh: true,
        });
      },
    });
  }, [emailTimeIntervalSettingsRequestStatus]);

  const isRequestPending = getIsRequestPending(rampUpSettingsRequestStatus);

  return (
    <>
      <div className="sending-settings-wrapper">
        <EmailDailySendingLimit featureUsages={featureUsages} />

        <TimeIntervalSetting
          isRequestPending={getIsRequestPending(
            emailTimeIntervalSettingsRequestStatus,
          )}
          onKeyPress={onKeyPress}
          emailAccountId={emailAccountId}
          sendEmailTimeIntervalRequest={sendEmailTimeIntervalRequest}
          minInterval={rampUpSettings?.minInterval}
          maxInterval={rampUpSettings?.maxInterval}
          setTimerIntervalSettingErrors={null}
          featureUsages={featureUsages}
        />

        <div className="daily-rampup-wrapper">
          <DailyRampUpToggle
            isChecked={dailyRampUp}
            onChange={onDailyRampUpChange}
            rampUpStatus={rampUpStatus}
            agencyConfig={agencyConfig}
          />

          <DailyRampUpContainer
            t={t}
            dailyRampUp={dailyRampUp}
            onKeyPress={onKeyPress}
            onDropAndPaste={onDropAndPaste}
            emailsPerDay={emailsPerDay}
            onEmailsPerDayChange={onEmailsPerDayChange}
            emailsPerDayError={emailsPerDayError}
            emailsPerDayPercent={emailsPerDayPercent}
            onEmailsPerDayPercentChange={onEmailsPerDayPercentChange}
            emailsPerDayPercentError={emailsPerDayPercentError}
            rampUpStatus={rampUpStatus}
            rampUpSettings={rampUpSettings}
            rampUpLimitReached={rampUpLimitReached}
            dirty={dirty}
          />

          {(dirty.dailyRampUp ||
            dirty.emailsPerDay ||
            dirty.emailsPerDayPercent) && (
            <Button
              variant={Button.Variant.Primary}
              onClick={(e) => onSaveHandler(e)}
              className="save-sending-settings header-btn"
              isLoading={isRequestPending}
              disabled={
                isRequestPending ||
                (!rampUpSettings.rampUpStatus && !dailyRampUp) ||
                emailsPerDayError !== '' ||
                emailsPerDayPercentError !== ''
              }
            >
              Save
            </Button>
          )}
        </div>
      </div>

      <SaveRampUpConfirmation
        show={saveConfirmationModal}
        onSubmit={updateRampUpSettings}
        onClose={hideSaveConfirmationModal}
        isRequestPending={isRequestPending}
        confirmationModalText={confirmationModalText}
      />
    </>
  );
};

export default SendingSettings;
