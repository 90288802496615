import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/root-reducer';

import CreateEmailInfrastructure from './create-email-infrastructure';

import { getDomainPlansRequest } from '../../extra-actions';
import {
  addEmailAccountToEmailAccountInfrastructureCart,
  removeDomainFromEmailAccountInfrastructureCart,
  removeEmailAccountFromEmailAccountInfrastructureCart,
  resetEmailAccountInfrastructureCart,
} from '../../email-accounts-slice';

const mapStateToProps = (state: RootState) => ({
  firstName: state.home.firstName,
  lastName: state.home.lastName,

  emailAccountInfrastructureServiceProvider:
    state.emailAccount.emailAccountInfrastructureServiceProvider,
  emailAccountInfrastructureCart:
    state.emailAccount.emailAccountInfrastructureCart,
  emailAccountInfrastructureForwardingDomain:
    state.emailAccount.emailAccountInfrastructureForwardingDomain,
  domains: state.emailAccount.domains,
  domainPlans: state.emailAccount.domainPlans,
  getDomainSearchRequestStatus:
    state.emailAccount.getDomainSearchRequest.status,

  hasErrorsInContactDetailsForm:
    state.emailAccount.hasErrorsInContactDetailsForm,
});

const mapDispatchToProps = {
  sendGetDomainPlansRequest: () => getDomainPlansRequest(),

  removeDomainFromEmailAccountInfrastructureCart: (payload: string) =>
    removeDomainFromEmailAccountInfrastructureCart(payload),

  addEmailAccountToEmailAccountInfrastructureCart: (payload) =>
    addEmailAccountToEmailAccountInfrastructureCart(payload),
  removeEmailAccountFromEmailAccountInfrastructureCart: (payload: string) =>
    removeEmailAccountFromEmailAccountInfrastructureCart(payload),

  resetEmailAccountInfrastructureCart: () =>
    resetEmailAccountInfrastructureCart(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(CreateEmailInfrastructure);
