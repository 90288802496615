/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import produce from 'immer';
import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Cross, InfoCircle } from '@saleshandy/icons';
import validate from './sub-components/validator';
import {
  CustomDomainDropdown,
  GeneralSettingsProps,
  GeneralSettingsState,
  UpdateEmailTabKeys,
} from '../types';
import Input from '../../../../../../../shared/design-system/components/input';
import { EmailAccountSettingCode } from '../../../../../enums/email-account-settings';
import { UpdateEmailAccountGeneralSettingsRequestPayload } from '../../../../../types/request-payload';
import Editor from '../../../../../../../shared/editor';
import Select from '../../../../../../../shared/design-system/components/select';
import { EmailAccount } from '../../../../../types/email-account';
import Button from '../../../../../../../shared/design-system/components/atoms/button';
import { getIsRequestPending } from '../helpers';
import { RequestStatus } from '../../../../../../../shared/enums/request-status';
import toaster, { Theme } from '../../../../../../../shared/toaster';
import { executeOnRequestStatusWithPrevStatusCheck } from '../../../../../../../shared/utils/execute-on-request-status';
import AgencyClientDropdown from '../../../../../../agency-client-management/components/agency-client-dropdown';
import { Placement } from '../../../../../../../shared/design-system/components/overlay/enums';
import {
  AgencyClientStatus,
  AgencyResourceType,
} from '../../../../../../agency-client-management/enums/agency-client';
import { OverlayTooltip } from '../../../../../../../shared/design-system/components/overlay';
import { isAgency } from '../../../../../../../shared/utils/user-details';
import { getClientName } from '../../../../../../agency-client-management/helpers/helpers';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';

class GeneralSettings extends React.Component<
  GeneralSettingsProps,
  GeneralSettingsState
> {
  constructor(props) {
    super(props);
    const { selectedAgencyClient: initialSelectedAgencyClient } = this.props;

    this.state = {
      values: {
        senderName: '',
        senderFirstName: '',
        senderLastName: '',
        bcc: '',
        signature: '',
      },
      errors: {
        senderName: '',
        senderFirstName: '',
        senderLastName: '',
        bcc: '',
        signature: '',
      },
      dirty: {
        senderName: false,
        senderFirstName: false,
        senderLastName: false,
        bcc: false,
        signature: false,
      },
      selectedCustomDomain: {
        key: null,
        value: null,
      },
      isCustomDomainOptionsFetched: false,
      isAgencyClientsOptionsFetched: false,
      selectedAgencyClient: initialSelectedAgencyClient?.id
        ? initialSelectedAgencyClient
        : {
            id: -1,
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            status: AgencyClientStatus.Active,
          },
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onSaveHandler = this.onSaveHandler.bind(this);
    this.onSignatureChange = this.onSignatureChange.bind(this);
    this.setComponentState = this.setComponentState.bind(this);
    this.resetComponentState = this.resetComponentState.bind(this);
  }

  componentDidMount() {
    this.setComponentState();
  }

  componentDidUpdate(prevProps: GeneralSettingsProps) {
    const {
      emailAccount,
      updateEmailAccountRequestStatus,
      getEmailAccountRequestStatus,
      t,
      tabKey,
      onCheckEmailScore,
      emailAccountId,
      hashId,
      sendGetEmailAccountRequest,
      sendGetEmailAccountSettingsRequest,
    } = this.props;
    const { selectedCustomDomain } = this.state;
    if (emailAccount !== prevProps.emailAccount) {
      this.setSelectedCustomDomain(emailAccount);
    }
    if (
      prevProps.updateEmailAccountRequestStatus !==
        updateEmailAccountRequestStatus &&
      updateEmailAccountRequestStatus === RequestStatus.Succeeded
    ) {
      toaster.success(t('messages.changes_updated_successfully'), {
        theme: Theme.New,
      });

      sendGetEmailAccountRequest(hashId);
      sendGetEmailAccountSettingsRequest(hashId);

      if (
        (selectedCustomDomain.key &&
          selectedCustomDomain.value &&
          emailAccount.emailAccountCustomDomain === null) ||
        (selectedCustomDomain.key &&
          selectedCustomDomain.value &&
          selectedCustomDomain.key !==
            emailAccount.emailAccountCustomDomain?.userCustomDomain?.id)
      ) {
        onCheckEmailScore({ emailAccountId, refresh: true });
      }
    }

    executeOnRequestStatusWithPrevStatusCheck({
      status: getEmailAccountRequestStatus,
      prevStatus: prevProps.getEmailAccountRequestStatus,
      onSuccess: () => {
        this.setComponentState();
      },
    });

    if (prevProps.tabKey !== tabKey) {
      if (tabKey === UpdateEmailTabKeys.GENERAL_SETTINGS) {
        this.setComponentState();
      }
    }
  }

  componentWillUnmount(): void {
    this.resetComponentState();
  }

  onInputChange(value, e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onSignatureChange(value) {
    this.setState(
      produce((draft) => {
        draft.values.signature = value;
        draft.dirty.signature = true;
      }),
    );
  }

  onInputBlur(e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name]);
        }
      }),
    );
  }

  onRemoveAssociation() {
    const { emailAccountId, sendUpdateAssignAgencyClientResource } = this.props;
    sendUpdateAssignAgencyClientResource({
      resourceType: AgencyResourceType.EmailAccount,
      resourceIds: [emailAccountId],
      clientId: 0, // de-assign the client
    });
  }

  onSaveHandler(e) {
    e.preventDefault();

    const {
      emailAccountId,
      emailAccount,
      sendUpdateEmailAccountRequest,
      selectedAgencyClient: initialSelectedAgencyClient,
    } = this.props;
    const {
      dirty,
      errors,
      values,
      selectedCustomDomain,
      selectedAgencyClient,
    } = this.state;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirty);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errors);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, values[key]);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    if (isError) {
      this.setState({ errors: errorsRef, dirty: dirtyRef });
      return;
    }

    let emailAccountCustomDomainId;

    if (emailAccount.emailAccountCustomDomain) {
      emailAccountCustomDomainId =
        emailAccount.emailAccountCustomDomain.userCustomDomain.id;
    }

    let clientId;
    if (
      initialSelectedAgencyClient?.id !== selectedAgencyClient?.id &&
      selectedAgencyClient?.id !== -1
    ) {
      clientId = selectedAgencyClient.id;
    }

    const payload: UpdateEmailAccountGeneralSettingsRequestPayload = {
      userCustomDomainId:
        selectedCustomDomain.key !== emailAccountCustomDomainId
          ? selectedCustomDomain.key
          : undefined,
      userCustomDomainIdToDelete: !selectedCustomDomain.key
        ? emailAccountCustomDomainId
        : undefined,
      fromName:
        values.senderLastName && values.senderLastName !== ''
          ? `${values.senderFirstName} ${values.senderLastName}`
          : values.senderFirstName,
      fromFirstName: values.senderFirstName,
      fromLastName: values.senderLastName,
      settings: [
        {
          code: EmailAccountSettingCode.Bcc,
          value: values.bcc,
        },
        {
          code: EmailAccountSettingCode.Signature,
          value: values.signature,
        },
      ],
      ...(clientId && { clientId }),
    };

    sendUpdateEmailAccountRequest(emailAccountId, payload);
  }

  onCustomDomainChange(option: CustomDomainDropdown) {
    this.setState({
      selectedCustomDomain: { key: option.key, value: option.domain },
    });
  }

  setSelectedCustomDomain(emailAccount: EmailAccount) {
    if (
      emailAccount.emailAccountCustomDomain &&
      emailAccount.emailAccountCustomDomain.userCustomDomain
    ) {
      const customDomain =
        emailAccount.emailAccountCustomDomain.userCustomDomain;

      this.setState({
        selectedCustomDomain: {
          value: customDomain.domain,
          key: customDomain.id,
        },
      });
    }
  }

  onTrashClick = () => {
    this.setState({
      selectedCustomDomain: { key: null, value: null },
    });
  };

  setComponentState() {
    const { emailAccount, emailAccountSettings } = this.props;

    if (emailAccount) {
      this.setSelectedCustomDomain(emailAccount);

      if (emailAccountSettings.length) {
        const { values } = this.state;
        const valuesRef = { ...values };
        valuesRef.senderName = emailAccount.fromName;
        valuesRef.senderFirstName = emailAccount.fromFirstName;
        valuesRef.senderLastName = emailAccount.fromLastName;

        emailAccountSettings.forEach((setting) => {
          switch (setting.code) {
            case EmailAccountSettingCode.Bcc:
              valuesRef.bcc = setting.value;
              break;
            case EmailAccountSettingCode.Signature:
              valuesRef.signature = setting.value;
              break;
            default:
              break;
          }
        });

        this.setState({
          values: valuesRef,
          errors: {
            senderName: '',
            senderFirstName: '',
            senderLastName: '',
            bcc: '',
            signature: '',
          },
          dirty: {
            senderName: false,
            senderFirstName: false,
            senderLastName: false,
            bcc: false,
            signature: false,
          },
        });
      }
    }
  }

  resetComponentState() {
    this.setState({
      values: {
        senderName: '',
        senderFirstName: '',
        senderLastName: '',
        bcc: '',
        signature: '',
      },
      errors: {
        senderName: '',
        senderFirstName: '',
        senderLastName: '',
        bcc: '',
        signature: '',
      },
      dirty: {
        senderName: false,
        senderFirstName: false,
        senderLastName: false,
        bcc: false,
        signature: false,
      },
      selectedCustomDomain: {
        key: null,
        value: null,
      },
      selectedAgencyClient: {
        id: -1,
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        status: AgencyClientStatus.Active,
      },
    });
  }

  render() {
    const { values, errors, selectedCustomDomain } = this.state;
    const {
      customDomainList,
      sendImageUploadRequest,
      updateEmailAccountRequestStatus,
      agencyClients,
      getCustomDomainsListRequestStatus,
      sendGetCustomDomainsListRequest,
      sendGetAgencyClientsDropdownRequest,
      getAgencyClientsDropdownRequestStatus,
    } = this.props;
    const { selectedAgencyClient } = this.state;
    const { selectedAgencyClient: initialSelectedAgencyClient } = this.props;
    const customDomainOptions: CustomDomainDropdown[] = customDomainList.map(
      (customDomain) => ({ ...customDomain, key: customDomain.id }),
    );

    const isRequestPending = getIsRequestPending(
      updateEmailAccountRequestStatus,
    );

    const isCustomTrackingDomainRequestPending = getIsRequestPending(
      getCustomDomainsListRequestStatus,
    );

    const isAgencyClientsRequestPending = getIsRequestPending(
      getAgencyClientsDropdownRequestStatus,
    );

    const onCustomDomainToggle = () => {
      const { isCustomDomainOptionsFetched } = this.state;
      if (
        hasPermission(Permissions.CUSTOM_DOMAIN_READ) &&
        !isCustomDomainOptionsFetched
      ) {
        sendGetCustomDomainsListRequest();
        this.setState({ isCustomDomainOptionsFetched: true });
      }
    };

    const onAgencyClientsToggle = () => {
      const { isAgencyClientsOptionsFetched } = this.state;
      if (
        hasPermission(Permissions.CLIENT_READ) &&
        isAgency() &&
        !isAgencyClientsOptionsFetched
      ) {
        sendGetAgencyClientsDropdownRequest({
          search: '',
          status: AgencyClientStatus.Active,
        });
        this.setState({ isAgencyClientsOptionsFetched: true });
      }
    };

    return (
      <div className="general-settings-wrapper">
        <Row className="mb-1">
          <Col
            hidden
            xs={6}
            md={6}
            className="general-settings-wrapper-max-col-width"
          >
            <Input
              name="senderName"
              label="Sender Name"
              variant={errors.senderName && Input.Variant.Error}
              caption={errors.senderName}
              size={Input.Size.Medium}
              value={values.senderName}
              onChange={this.onInputChange}
              placeholder="Enter sender name"
              onBlur={this.onInputBlur}
              autoFocus
              className="general-settings-input"
            />
          </Col>
          <Col xs={6} md={6} className="general-settings-wrapper-max-col-width">
            <Input
              name="senderFirstName"
              label="First Name"
              variant={errors.senderFirstName && Input.Variant.Error}
              caption={errors.senderFirstName}
              size={Input.Size.Medium}
              value={values.senderFirstName}
              onChange={this.onInputChange}
              placeholder="Enter sender name"
              onBlur={this.onInputBlur}
              autoFocus
              className="general-settings-input"
            />
          </Col>
        </Row>

        <Row className="mb-1">
          <Col xs={6} md={6} className="general-settings-wrapper-max-col-width">
            <Input
              name="senderLastName"
              label="Last Name"
              variant={errors.senderLastName && Input.Variant.Error}
              caption={errors.senderLastName}
              size={Input.Size.Medium}
              value={values.senderLastName}
              onChange={this.onInputChange}
              placeholder="Enter sender name"
              onBlur={this.onInputBlur}
              className="general-settings-input"
            />
          </Col>
        </Row>

        {hasPermission(Permissions.CUSTOM_DOMAIN_READ) && (
          <Row className="mb-1">
            <Col
              xs={6}
              md={6}
              className="general-settings-wrapper-max-col-width"
            >
              <div className="bs-input-container general-settings-input">
                <label className="bs-input-label-prefix">
                  Custom Tracking Domain
                </label>
                <div className="select-container">
                  <Select<CustomDomainDropdown>
                    className="custom-domain-select"
                    placeholder="Select Custom Domain"
                    options={customDomainOptions}
                    selectedOptionKey={selectedCustomDomain.key}
                    selectedOptionRenderer={() => (
                      <span>{selectedCustomDomain.value}</span>
                    )}
                    onChange={([option]) => {
                      this.onCustomDomainChange(option);
                    }}
                    optionRenderer={(option) => <span>{option?.domain}</span>}
                    onToggle={(next) => {
                      if (next) onCustomDomainToggle();
                    }}
                    isLoading={isCustomTrackingDomainRequestPending}
                  />
                </div>
              </div>
            </Col>
          </Row>
        )}

        {hasPermission(Permissions.CLIENT_READ) && isAgency() && (
          <Row className="mb-1">
            <Col
              xs={6}
              md={6}
              className="general-settings-wrapper-max-col-width"
            >
              <div className="bs-input-container general-settings-input agency-dropdown">
                <label className="bs-input-label-prefix">
                  Associate Client
                </label>
                <AgencyClientDropdown
                  options={agencyClients}
                  initialSelectedOption={selectedAgencyClient}
                  oldSelectedOption={initialSelectedAgencyClient}
                  className="sequence-associated-client general-settings-agency-dropdown"
                  onChange={(option) => {
                    if (option) {
                      this.setState({ selectedAgencyClient: option });
                    }
                  }}
                  optionRenderer={(option) => (
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div>
                        {getClientName(option, 60, {
                          company: true,
                          email: true,
                          deleted: true,
                        })}
                      </div>
                      {initialSelectedAgencyClient?.id === option?.id && (
                        <button
                          onClick={() => this.onRemoveAssociation()}
                          type="button"
                        >
                          <OverlayTooltip
                            text="Remove Client Association"
                            placement={Placement.BottomStart}
                          >
                            <Cross height={16} width={16} color="#6B7280" />
                          </OverlayTooltip>
                        </button>
                      )}
                    </div>
                  )}
                  onRemoveAssociation={() => this.onRemoveAssociation()}
                  disabled={false}
                  placement={Placement.TopStart}
                  onToggle={(next) => {
                    if (next) onAgencyClientsToggle();
                  }}
                  isLoading={isAgencyClientsRequestPending}
                />
              </div>
            </Col>
          </Row>
        )}

        <Row className="mb-1">
          <Col xs={6} md={6} className="general-settings-wrapper-max-col-width">
            <Input
              name="bcc"
              label="BCC"
              placeholder="Enter your bcc email address"
              value={values.bcc}
              size={Input.Size.Medium}
              variant={errors.bcc && Input.Variant.Error}
              caption={errors.bcc}
              onChange={this.onInputChange}
              onBlur={this.onInputBlur}
              className="general-settings-input"
            />
          </Col>
        </Row>

        <Row>
          <Col xs={6} md={6} className="general-settings-wrapper-max-col-width">
            <div className="bs-input-container signature-editor general-settings-input">
              <label className="bs-input-label-prefix">
                Signature{' '}
                <OverlayTooltip text="Use the {{Sender Signature}} Variable Tags to insert your signature anywhere in your email">
                  <InfoCircle className="gray-txt-11" />
                </OverlayTooltip>
              </label>
              <Editor
                value={values.signature}
                onEditorChange={this.onSignatureChange}
                imageUploader={({ formData, onUploadProgress }) =>
                  sendImageUploadRequest(formData, onUploadProgress)
                }
                showAddAttachments={false}
              />
            </div>
          </Col>
        </Row>

        <Button
          variant={Button.Variant.Primary}
          onClick={(e) => this.onSaveHandler(e)}
          className="save-sending-settings header-btn"
          isLoading={isRequestPending}
          disabled={isRequestPending}
        >
          Save
        </Button>
      </div>
    );
  }
}

export default withTranslation()(GeneralSettings);
