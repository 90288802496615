import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@saleshandy/design-system';
import {
  Adjustments,
  Filter,
  Refresh,
  Trash,
  Unlink,
  ArrowUpRight,
  World,
} from '@saleshandy/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import type { IProps } from './email-accounts-actions-container';
import SearchInput from '../../../../../../shared/design-system/components/atoms/search-input';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import {
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import ConfirmationModalV3 from '../../../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { ConfirmationModalIconType } from '../../../../../../shared/design-system/components/atoms/confirmation-modal/enum';
import { OverlayTooltip } from '../../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';

import AddRemoveTag from '../../../../../../shared/components/add-remove-tag';
import { RootState } from '../../../../../../store/root-reducer';
import {
  emailAccountTagsAssignRequest,
  emailAccountTagsBulkAssignRequest,
  emailAccountTagsBulkUnassignRequest,
  emailAccountTagsUnassignRequest,
  getEmailAccountsRequest,
} from '../../../../extra-actions';
import toaster, { Theme } from '../../../../../../shared/toaster';
import EmailAccountsFiltersModal from '../email-accounts-filters-modal';
import { resetSelectedEmailAccount } from '../../../../email-accounts-slice';
import { EmailAccountFilterStatus } from '../../../../types/email-account';
import hasResource from '../../../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../../../shared/utils/access-control/enums/resource-identifiers';

const EmailAccountsActions: React.FC<IProps> = ({
  onTrulyInboxEmailSync,
  onFiltersChange,
  openBulkEmailUpdateModal,
  selectedEmailAccounts,
  bulkSelectedEmailAccounts,
  getConnectedUsersAndEmailAccountsRequestStatus,
  sendBulkEmailAccountUpdateRequest,
  bulkEmailUpdateRequestStatus,
  syncConsentRequestResponse,
  syncConsentRequestStatus,
  onLoginWithTrulyInbox,
  getEmailAccountsRequestStatus,
  emailAccountsFilters,
  emailAccountTagsList,
  resetEmailAccountTagsList,
  sendGetEmailAccountsTagsRequest,
  getTrulyInboxTokenRequestStatus,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [search, setSearch] = useState(emailAccountsFilters.search || '');
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const addTagsRequestStatus = useSelector(
    (state: RootState) =>
      state.emailAccount.emailAccountTagsAssignRequest.status,
  );

  const removeTagsRequestStatus = useSelector(
    (state: RootState) =>
      state.emailAccount.emailAccountTagsUnassignRequest.status,
  );

  const bulkAddTagsRequestStatus = useSelector(
    (state: RootState) =>
      state.emailAccount.emailAccountTagsBulkAssignRequest.status,
  );

  const bulkRemoveTagsRequestStatus = useSelector(
    (state: RootState) =>
      state.emailAccount.emailAccountTagsBulkUnassignRequest.status,
  );

  // const [clientIds, setClientIds] = useState(filters.clientIds || []);

  const openEmailAccountsFiltersModal = () => setIsFiltersModalOpen(true);
  const closeEmailAccountsFiltersModal = () => setIsFiltersModalOpen(false);

  const [
    showDisconnectEmailConfirmationPopup,
    setShowDisconnectEmailConfirmationPopup,
  ] = useState(false);
  const [
    showDeleteEmailConfirmationPopup,
    setShowDeleteEmailConfirmationPopup,
  ] = useState(false);

  const onSearch = () => {
    onFiltersChange({ search, page: 1 });
  };

  const onClearSearch = () => {
    setSearch('');
    onFiltersChange({ search: '', page: 1 });
  };

  const onHideBulkEmailDisconnectConfirmationModal = () => {
    setShowDisconnectEmailConfirmationPopup(false);
  };

  const onHideBulkEmailDeleteConfirmationModal = () => {
    setShowDeleteEmailConfirmationPopup(false);
  };

  // Initialize refs outside the array
  const bulkUpdateSettingsRef = useRef(null);
  const refreshSetupScoreRef = useRef(null);
  const disconnectEmailAccountsRef = useRef(null);
  const deleteEmailAccountsRef = useRef(null);
  const addRemoveTagRef = useRef(null);

  const actions = [
    (hasPermission(Permissions.EMAIL_ACCOUNT_SETTINGS_GENERAL_SETTING_UPDATE) ||
      hasPermission(
        Permissions.EMAIL_ACCOUNT_SETTINGS_SENDING_SETTING_UPDATE,
      )) && {
      key: 'bulk_update_settings',
      tooltip: 'Bulk update Settings',
      ref: bulkUpdateSettingsRef,
      icon: <Adjustments height={24} width={24} />,
      onClick: () => {
        openBulkEmailUpdateModal();
      },
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_SETTINGS_SETUP_SCORE) && {
      key: 'refresh_setup_score',
      tooltip: 'Refresh Setup Score ',
      ref: refreshSetupScoreRef,
      icon: <Refresh height={24} width={24} />,
      onClick: () => {
        sendBulkEmailAccountUpdateRequest({
          refreshSetupScore: true,
          ...(bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
            ? {
                allEmailsSelected: true,
                ignoreEmailAccountIds:
                  bulkSelectedEmailAccounts?.deSelectedEmailAccountIds || [],
              }
            : {
                emailAccountIds: selectedEmailAccounts?.map((obj) => obj.id),
              }),
        });
      },
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_DISCONNECT) && {
      key: 'disconnect_email_accounts',
      tooltip: 'Disconnect Email Accounts',
      ref: disconnectEmailAccountsRef,
      icon: <Unlink height={24} width={24} />,
      onClick: () => {
        setShowDisconnectEmailConfirmationPopup(true);
      },
    },
    hasPermission(Permissions.EMAIL_ACCOUNT_DELETE) && {
      key: 'delete_email_accounts',
      tooltip: 'Delete Email Accounts',
      ref: deleteEmailAccountsRef,
      icon: <Trash height={24} width={24} />,
      onClick: () => {
        setShowDeleteEmailConfirmationPopup(true);
      },
    },
  ].filter((opt) => opt);

  const handleDisconnectEmailAccounts = () => {
    sendBulkEmailAccountUpdateRequest({
      disconnectEmailAccounts: true,
      ...(bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
        ? {
            allEmailsSelected: true,
            ignoreEmailAccountIds:
              bulkSelectedEmailAccounts?.deSelectedEmailAccountIds || [],
          }
        : {
            emailAccountIds: selectedEmailAccounts?.map((obj) => obj.id),
          }),
    });
  };

  const handleDeleteEmailAccounts = () => {
    sendBulkEmailAccountUpdateRequest({
      deleteEmailAccounts: true,
      ...(bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
        ? {
            allEmailsSelected: true,
            ignoreEmailAccountIds:
              bulkSelectedEmailAccounts?.deSelectedEmailAccountIds || [],
          }
        : {
            emailAccountIds: selectedEmailAccounts?.map((obj) => obj.id),
          }),
    });
  };

  function getEmailAccountFilterStatus(status: any) {
    if (Array.isArray(status) && status.length === 0) return null;
    return status === 'Active'
      ? EmailAccountFilterStatus.Active
      : EmailAccountFilterStatus.Disconnected;
  }

  const getFilteredEmailAccountFilter = (filters) => {
    const {
      status,
      owners,
      sequenceId,
      tagIds,
      emailServiceProvider,
      clientIds,
    } = filters;

    return {
      status: getEmailAccountFilterStatus(status),
      owners,
      sequenceIds: sequenceId || [],
      tagIds,
      emailServiceProvider,
      clientIds,
    };
  };

  const handleBulkAddTag = async (tags) => {
    const payload = {
      deSelectedEmailAccountIds:
        bulkSelectedEmailAccounts?.deSelectedEmailAccountIds,
      ids: tags.filter((tag) => tag?.id).map((tag) => tag.id),
      newTags: tags.filter((tag) => tag.isNew).map((tag) => tag.name),
      emailAccountFilter: getFilteredEmailAccountFilter(emailAccountsFilters),
    };

    await dispatch(emailAccountTagsBulkAssignRequest(payload));
    resetEmailAccountTagsList();
  };

  const handleAddTag = async (tags) => {
    if (bulkSelectedEmailAccounts?.isAllEmailAccountsSelected) {
      handleBulkAddTag(tags);
      return;
    }

    const payload = {
      emailAccountIds: selectedEmailAccounts.map((email) => email.id),
      ids: [],
      newTags: tags.map((tag) => tag.name),
    };

    await dispatch(emailAccountTagsAssignRequest(payload));
    resetSelectedEmailAccount();
    resetEmailAccountTagsList();
  };

  const handleBulkRemoveTag = async (tags) => {
    const payload = {
      deSelectedEmailAccountIds:
        bulkSelectedEmailAccounts?.deSelectedEmailAccountIds,
      tagIds: tags.filter((tag) => tag?.id).map((tag) => tag.id),
      emailAccountFilter: getFilteredEmailAccountFilter(emailAccountsFilters),
    };

    await dispatch(emailAccountTagsBulkUnassignRequest(payload));
    resetEmailAccountTagsList();
  };

  const handleRemoveTag = (tags) => {
    if (bulkSelectedEmailAccounts?.isAllEmailAccountsSelected) {
      handleBulkRemoveTag(tags);
      return;
    }

    const payload = {
      emailAccountIds: selectedEmailAccounts
        .filter((obj) => obj.id !== undefined)
        .map((email) => email.id),
      tagIds: tags.map((tag) => tag.id),
    };

    dispatch(emailAccountTagsUnassignRequest(payload));
    resetEmailAccountTagsList();
  };

  useEffect(() => {
    if (showModal) {
      if (emailAccountTagsList.length === 0) {
        sendGetEmailAccountsTagsRequest();
      }
    }
  }, [showModal]);

  useEffect(() => {
    executeOnRequestStatus({
      status: addTagsRequestStatus,
      onSuccess: () => {
        addRemoveTagRef?.current?.closeModal();
        toaster.success('Tags applied successfully', { theme: Theme.New });
        dispatch(getEmailAccountsRequest(emailAccountsFilters));
        dispatch(resetEmailAccountTagsList);
      },
    });
  }, [addTagsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: removeTagsRequestStatus,
      onSuccess: () => {
        addRemoveTagRef?.current?.closeModal();
        toaster.success('Tags removed successfully', { theme: Theme.New });
        dispatch(getEmailAccountsRequest(emailAccountsFilters));
        dispatch(resetEmailAccountTagsList);
      },
    });
  }, [removeTagsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: bulkAddTagsRequestStatus,
      onSuccess: () => {
        addRemoveTagRef?.current?.closeModal();
        toaster.success('Tags applied successfully', { theme: Theme.New });
        dispatch(getEmailAccountsRequest(emailAccountsFilters));
        dispatch(resetEmailAccountTagsList);
      },
    });
  }, [bulkAddTagsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: bulkRemoveTagsRequestStatus,
      onSuccess: () => {
        addRemoveTagRef?.current?.closeModal();
        toaster.success('Tags removed successfully', { theme: Theme.New });
        dispatch(getEmailAccountsRequest(emailAccountsFilters));
        dispatch(resetEmailAccountTagsList);
      },
    });
  }, [bulkRemoveTagsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: bulkEmailUpdateRequestStatus,
      onSuccess: () => {
        onHideBulkEmailDisconnectConfirmationModal();
        onHideBulkEmailDeleteConfirmationModal();
      },
      onFailed: () => {
        onHideBulkEmailDisconnectConfirmationModal();
        onHideBulkEmailDeleteConfirmationModal();
      },
    });
  }, [bulkEmailUpdateRequestStatus]);

  const actionClassName = classNames([
    'icon d-flex align-items-center justify-content-center',
    {
      disabled:
        !selectedEmailAccounts?.length &&
        !bulkSelectedEmailAccounts?.isAllEmailAccountsSelected,
    },
  ]);

  const bulkUpdateLoading = getIsRequestPending(bulkEmailUpdateRequestStatus);

  const activeFilterCount = useMemo(
    () =>
      Object.values(emailAccountsFilters).reduce((count: number, filter) => {
        if (Array.isArray(filter) && filter.length > 0) {
          return count + 1; // Only count non-empty arrays
        }
        return count; // Skip other types and empty arrays
      }, 0),
    [emailAccountsFilters],
  );
  const isSyncConsentRequestPending = getIsRequestPending(
    syncConsentRequestStatus,
  );

  const redirectToDomains = () => {
    history.push('/domains');
  };

  return (
    <>
      <div className="email-accounts-actions">
        <div className="d-flex align-items-center">
          <SearchInput
            search={search}
            onSearch={onSearch}
            onClearSearch={onClearSearch}
            placeholder="Search"
            setSearch={setSearch}
          />
          {/* {hasPermission(Permissions.CLIENT_READ) &&
            isAgency() &&
            (isAssociatedClientRequestPending ? (
              <SkeletonLoading
                height={32}
                width={187}
                className="agency-client-email-accounts"
              />
            ) : (
              <AgencyClientFilters
                clientIds={emailAccountsFilters.clientIds || []}
                onClientIdsChange={(newClientIds) =>
                  updateEmailAccountsFilters({
                    ...emailAccountsFilters,
                    clientIds: newClientIds,
                  })
                }
                btnClassName="agency-client-email-accounts btn-white"
              />
            ))} */}

          <div className="icon-container position-relative">
            <div
              className="d-flex align-items-center justify-content-center icon"
              {...accessibleOnClick(openEmailAccountsFiltersModal)}
            >
              <Filter width={24} height={24} />
            </div>

            {activeFilterCount > 0 && (
              <span className="filter-count-badge">{activeFilterCount}</span>
            )}
          </div>

          <div className="separator-line" />
          <div className="d-flex align-items-center justify-content-center email-account-icons">
            {actions.map((action) => (
              <OverlayTooltip text={t(`labels.${action.key}`)}>
                <div
                  ref={action.ref}
                  className={actionClassName}
                  {...accessibleOnClick(() => {
                    if (action.ref.current) {
                      action.ref.current.blur();
                    }
                    if (
                      selectedEmailAccounts?.length > 0 ||
                      bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
                    ) {
                      action.onClick();
                    }
                  })}
                >
                  {action.icon}
                </div>
              </OverlayTooltip>
            ))}

            {/* <AddRemoveTag
              handleAddTag={handleAddTag}
              handleRemoveTag={handleRemoveTag}
              selectedTags={selectedTags}
              selectedProspects={selectedEmailAccounts}
              isSubmitLoading={
                getIsRequestPending(addTagsRequestStatus) ||
                getIsRequestPending(removeTagsRequestStatus)
              }
              leadTags={emailAccountTagsList}
              isNotRenderDropdown={true}
            /> */}
            <AddRemoveTag
              ref={addRemoveTagRef}
              handleAddTag={handleAddTag}
              handleRemoveTag={handleRemoveTag}
              tags={emailAccountTagsList}
              selectedRecords={selectedEmailAccounts}
              disabled={
                !selectedEmailAccounts?.length &&
                !bulkSelectedEmailAccounts?.isAllEmailAccountsSelected
              }
              isShowOnlyTagIcon={true}
              isSubmitLoading={
                getIsRequestPending(addTagsRequestStatus) ||
                getIsRequestPending(removeTagsRequestStatus) ||
                getIsRequestPending(bulkAddTagsRequestStatus) ||
                getIsRequestPending(bulkRemoveTagsRequestStatus)
              }
              setShowModal={setShowModal}
              showModal={showModal}
              bulkSelectedOptions={bulkSelectedEmailAccounts}
            />
          </div>
        </div>
        <div className="d-flex gap-16 justify-content-center align-items-center">
          {hasPermission(Permissions.TI_SYNC_CONSENT) &&
            (isSyncConsentRequestPending ? (
              <Button
                variant="secondary"
                icon={Refresh}
                iconPlacement="left"
                className="header-btn"
                isLoading={true}
                loadingText="Loading..."
              >
                Loading...
              </Button>
            ) : (
              <>
                {syncConsentRequestResponse?.shConsent &&
                  !syncConsentRequestResponse.shConsent.consent && (
                    <Button
                      onClick={onTrulyInboxEmailSync}
                      variant="secondary"
                      icon={Refresh}
                      iconPlacement="left"
                      className="header-btn"
                      isLoading={
                        getIsRequestPending(getEmailAccountsRequestStatus) ||
                        getIsRequestPending(syncConsentRequestStatus)
                      }
                      loadingText="Sync Email with TrulyInbox"
                    >
                      Sync Email with TrulyInbox
                    </Button>
                  )}
                <OverlayTooltip text="Domains">
                  <World
                    width={24}
                    height={24}
                    color="#6B7280"
                    cursor="pointer"
                    onClick={redirectToDomains}
                  />
                </OverlayTooltip>

                {syncConsentRequestResponse?.shConsent?.consent && (
                  <Button
                    onClick={onLoginWithTrulyInbox}
                    variant="secondary"
                    iconPlacement="left"
                    isLoading={
                      getIsRequestPending(getEmailAccountsRequestStatus) ||
                      getIsRequestPending(syncConsentRequestStatus) ||
                      getIsRequestPending(getTrulyInboxTokenRequestStatus)
                    }
                    className="header-btn login-with-trulyinbox"
                  >
                    Warm-up Dashboard
                    <ArrowUpRight
                      width={16}
                      height={16}
                      className="blue-txt-11 ml-1"
                    />
                  </Button>
                )}
              </>
            ))}

          {hasPermission(Permissions.EMAIL_ACCOUNT_CONNECT) && (
            <Button
              onClick={() => {
                history.push('/email-accounts/create');
              }}
              disabled={!hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT)}
              className="header-btn"
              isLoading={getIsRequestPending(
                getConnectedUsersAndEmailAccountsRequestStatus,
              )}
              loadingText="Add Email Account"
            >
              Add Email Account
            </Button>
          )}
        </div>
      </div>

      {isFiltersModalOpen && (
        <EmailAccountsFiltersModal
          show={isFiltersModalOpen}
          onClose={closeEmailAccountsFiltersModal}
          emailAccountsFilters={emailAccountsFilters}
          onFiltersChange={onFiltersChange}
        />
      )}

      {showDisconnectEmailConfirmationPopup && (
        <ConfirmationModalV3
          show={showDisconnectEmailConfirmationPopup}
          onClose={onHideBulkEmailDisconnectConfirmationModal}
          onSubmit={handleDisconnectEmailAccounts}
          title={t('labels.disconnect_email_accounts')}
          content={t('messages.disconnect_email_accounts_desc')}
          submitButtonText={t('labels.yes')}
          cancelButtonText={t('labels.cancel')}
          iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
          className="email-account-confirmation-popup disconnect"
          isSubmitLoading={bulkUpdateLoading}
          isSubmitDisabled={bulkUpdateLoading}
        />
      )}
      {showDeleteEmailConfirmationPopup && (
        <ConfirmationModalV3
          show={showDeleteEmailConfirmationPopup}
          onClose={onHideBulkEmailDeleteConfirmationModal}
          onSubmit={handleDeleteEmailAccounts}
          title={t('labels.delete_email_accounts')}
          content={
            <span>
              {t('messages.delete_email_message_one')}{' '}
              {t('messages.delete_email_message_two')}
              {t('messages.delete_email_message_three')}
            </span>
          }
          submitButtonText={t('labels.yes')}
          cancelButtonText={t('labels.cancel')}
          iconType={ConfirmationModalIconType.TRASH_RED}
          className="email-account-confirmation-popup delete"
          isSubmitLoading={bulkUpdateLoading}
          isSubmitDisabled={bulkUpdateLoading}
          showCloseIcon={false}
        />
      )}
    </>
  );
};

export default EmailAccountsActions;
