import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';

import { EmailAccountsFilters } from '../../../../types/email-account';
import EmailAccountsActions from './email-accounts-actions';

import { getAgencyClientsDropdown } from '../../../../../agency-client-management/agency-client-slice';
import { GetAgencyClientDropdownRequestPayload } from '../../../../../agency-client-management/types/request-payload';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import {
  bulkEmailUpdateRequest,
  emailAccountTagsAssignRequest,
  getEmailAccountsRequest,
  getEmailAccountTagsRequest,
  trulyinboxEmailAccountVerifyShApiKeyRequest,
} from '../../../../extra-actions';
import { EmailAccountTagsAssignPayload } from '../../../../types/request-payload';
import { resetEmailAccountTagsList } from '../../../../email-accounts-slice';
import { getTagsRequest } from '../../../../../prospect/extra-actions';

const mapStateToProps = (state: RootState) => ({
  planCode: state.home.subscription?.planCode,
  selectedEmailAccounts: state.emailAccount.selectedEmailAccounts,
  bulkSelectedEmailAccounts: state.emailAccount.bulkSelectedEmailAccounts,

  getConnectedUsersAndEmailAccountsRequestStatus:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest
      .status,

  bulkEmailUpdateRequestStatus:
    state.emailAccount.bulkEmailUpdateRequest.status,
  getAgencyClientsDropdownRequestStatus:
    state.agencyClient.getAgencyClientsDropdownRequest.status,
  syncConsentRequestStatus:
    state.emailAccount.trulyinboxEmailAccountVerifyShApiKeyRequest.status,
  syncConsentRequestResponse:
    state.emailAccount.sendTrulyinboxEmailAccountVerifyShApiKeyResponse,
  emailAccountsFilters: state.emailAccount.emailAccountsFilters,

  emailAccountTagsList: state.emailAccount.emailAccountTagsList,
  getTrulyInboxTokenRequestStatus:
    state.emailAccount.getTrulyInboxTokenRequest.status,
});

const mapDispatchToProps = {
  sendBulkEmailAccountUpdateRequest: (payload) =>
    bulkEmailUpdateRequest(payload),
  sendTrulyinboxEmailAccountVerifyShApiKeyRequest: () =>
    trulyinboxEmailAccountVerifyShApiKeyRequest(),
  sendGetAgencyClientsDropdownRequest: (
    payload: GetAgencyClientDropdownRequestPayload,
  ) => getAgencyClientsDropdown(payload),
  sendGetEmailAccountsTagsRequest: () => getEmailAccountTagsRequest(),
  sendEmailAccountTagsAssignRequest: (payload: EmailAccountTagsAssignPayload) =>
    emailAccountTagsAssignRequest(payload),
  sendGetTagsRequest: () => getTagsRequest(),
  resetEmailAccountTagsList: () => resetEmailAccountTagsList(),
  sendGetEmailAccountsRequest: (filters: EmailAccountsFilters) =>
    getEmailAccountsRequest(filters),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  onAddEmailAccount: VoidFunction;
  onTrulyInboxEmailSync: VoidFunction;
  onLoginWithTrulyInbox: VoidFunction;
  getEmailAccountsRequestStatus: RequestStatus;
  openBulkEmailUpdateModal?: () => void;
  onFiltersChange: (partialFilters: Partial<EmailAccountsFilters>) => void;
};

export default connector(EmailAccountsActions);
