import React from 'react';
import { Button } from '@saleshandy/design-system';
import { Images } from '../../app-constants';
import { accessibleOnClick } from '../../utils/accessible-on-click';
import {
  ActionableSelectDropdown,
  ScoreFactorDetailItemProps,
  ScoreFactorStatus,
} from './types';
import {
  OverlayTooltip,
  Placement,
} from '../../design-system/components/overlay';
import {
  getEmailAccountIcon,
  getEmailAgeActionableDropdownData,
} from './helper';
import { EmailAccountType } from '../../../components/email-accounts/enums/email-account';
import SMTPLabel from '../../../components/email-accounts/components/email-accounts-content/utils/smtp-label';
import ImageIcon from '../images/image-icon';
import Switch, { Size } from '../../design-system/components/atoms/switch';
import Select from '../../design-system/components/select/select';

const ScoreFactorDetailItem: React.FC<ScoreFactorDetailItemProps> = ({
  scoreFactorStatus,
  title,
  isAllowedToViewYTVideoGuide = false,
  hideYoutubeIcon,
  youtubeIconTooltipText,
  onViewYoutubeVideo,
  description,
  hasActionable,
  actionableText,
  actionableHandler,
  showActionableList = false,
  actionableList = [],
  showActionWithListOnly = false,
  actionableType = 'link-btn',
  isToggledChecked = false,
  dropdownSelectedKey = '0',
}) => {
  const selectDropdown = (emailAccountId: number) => (
    <div className="score-factor-details-actionable">
      <Select<ActionableSelectDropdown>
        className="score-factor-details-actionable--select"
        options={getEmailAgeActionableDropdownData()}
        placement={Placement.Top}
        selectedOptionKey={dropdownSelectedKey}
        optionRenderer={(option) => <span>{option?.label}</span>}
        onChange={([option]) => {
          actionableHandler({
            emailAccountId,
            ageInMonths: option.value,
          });
        }}
        selectedOptionRenderer={([option]) => <span>{option?.label}</span>}
      />
    </div>
  );

  return (
    <div className="score-factor-details-wrapper">
      <div className="score-factor-details-content">
        <div className="score-factor-details-title">
          {scoreFactorStatus === ScoreFactorStatus.Valid && (
            <img src={Images.ChecksGreen} alt="Icon" />
          )}
          {scoreFactorStatus === ScoreFactorStatus.PartiallyValid && (
            <img src={Images.CheckSingleOrange} alt="Icon" />
          )}
          {scoreFactorStatus === ScoreFactorStatus.NotValid && (
            <img src={Images.AlertTriangle} alt="Icon" />
          )}

          <p>{title}</p>

          {isAllowedToViewYTVideoGuide && !hideYoutubeIcon && (
            <OverlayTooltip
              placement={Placement.Top}
              text={youtubeIconTooltipText}
            >
              <img
                {...accessibleOnClick(onViewYoutubeVideo)}
                src={Images.YoutubeLogo}
                alt="Icon"
                className="ml-1"
              />
            </OverlayTooltip>
          )}
        </div>
        <div className="score-factor-details-description">{description}</div>
        {showActionableList && (
          <div className="score-factor-details-actionable-list">
            {actionableList.map((item) => (
              <div className="score-factor-details-actionable-list-item">
                <h3>
                  {item.fromEmail}

                  {item.type === EmailAccountType.SmtpImap ? (
                    <SMTPLabel />
                  ) : (
                    <ImageIcon src={getEmailAccountIcon(item.type)} />
                  )}
                </h3>

                {showActionWithListOnly && actionableType === 'link-btn' && (
                  <div className="score-factor-details-actionable">
                    <Button
                      variant="link"
                      onClick={() => {
                        actionableHandler(item);
                      }}
                    >
                      {actionableText}
                    </Button>
                  </div>
                )}
                {showActionWithListOnly &&
                  actionableType === 'select' &&
                  selectDropdown(item.id)}
              </div>
            ))}
          </div>
        )}
      </div>
      {hasActionable &&
        !showActionWithListOnly &&
        actionableType === 'link-btn' && (
          <div className="score-factor-details-actionable">
            <Button
              variant="link"
              className="p-0"
              onClick={() => {
                actionableHandler();
              }}
            >
              {actionableText}
            </Button>
          </div>
        )}
      {hasActionable && !showActionWithListOnly && actionableType === 'switch' && (
        <div className="score-factor-details-actionable">
          <Switch
            checked={isToggledChecked}
            size={Size.Medium}
            onChange={() => actionableHandler()}
          />
        </div>
      )}
      {hasActionable &&
        !showActionWithListOnly &&
        actionableType === 'select' &&
        selectDropdown(null)}
    </div>
  );
};

export default ScoreFactorDetailItem;
