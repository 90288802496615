/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { InfoCircle } from '@saleshandy/icons';
import { Country, State, City } from 'country-state-city';
import Input from '../../../../../../shared/design-system/components/input';
import type { IProps } from './contact-details-tab-container';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';

import InputLabel from '../../../../../../shared/design-system/components/input/input-label';
import BillingSummaryModal from '../../../../modals/billing-summary-modal';
import { formatCreateEmailInfrastructurePayload } from '../../utils/format-create-email-infrastructure-payload';
import toaster from '../../../../../../shared/toaster';
import {
  clearAllEmailAccountInfrastructureKeysFromLocalStorage,
  getFromLocalStorage,
  LOCAL_STORAGE_KEYS,
  saveToLocalStorage,
} from '../../../../helpers/email-accounts-infra-local-storage';

const ContactDetailsForm: React.FC<IProps> = ({
  sendDomainOwnerContactDetailsRequest,
  domainOwnerContactDetails,
  hasErrorsInContactDetailsForm,

  updateHasErrorsInContactDetailsForm,
  showBillingSummaryModal,
  setShowBillingSummaryModal,
  emailAccountInfrastructureServiceProvider,
  emailAccountInfrastructureForwardingDomain,
  emailAccountInfrastructureCart,
  purchaseDomainRequestStatus,
  purchaseDomainRequestError,
  purchaseDomainResponse,
  sendPurchaseDomainRequest,
  resetPurchaseDomainRequest,
}) => {
  const history = useHistory();

  const [values, setValues] = useState({
    domainOwnerFirstname: '',
    domainOwnerLastname: '',
    companyName: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    phoneNumber: '',
  });

  const [errors, setErrors] = useState({
    domainOwnerFirstname: '',
    domainOwnerLastname: '',
    companyName: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    phoneNumber: '',
  });

  const fieldLabels: Record<string, string> = {
    domainOwnerFirstname: 'First name',
    domainOwnerLastname: 'Last name',
    companyName: 'Company name',
    address: 'Address',
    city: 'City',
    state: 'State',
    country: 'Country',
    zipcode: 'ZIP / Postal code',
    phoneNumber: 'Phone number',
  };

  // Api initialization check
  const [isInitialized, setIsInitialized] = useState(false);

  const [countryCode, setCountryCode] = useState('us');
  const phoneInputField = useRef<HTMLInputElement>(null);

  const [countries, setCountries] = useState(Country.getAllCountries());
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  // Validator function with dynamic rules
  const validate = (fieldName: string, value: string) => {
    if (fieldName === 'state' && states.length <= 0) {
      setErrors((prev) => ({ ...prev, state: '', city: '' }));
    } else if (fieldName === 'state' && states.length > 0 && !values.state) {
      setErrors((prev) => ({ ...prev, state: 'State cannot be empty' }));
    }

    if (fieldName === 'city' && cities.length <= 0) {
      setErrors((prev) => ({ ...prev, city: '' }));
    } else if (fieldName === 'city' && cities.length > 0 && !values.city) {
      setErrors((prev) => ({ ...prev, city: 'City cannot be empty' }));
    }

    if (!value || value.trim() === '') {
      return `${fieldLabels[fieldName]} should not be empty.`;
    }

    return '';
  };

  const handleInputChange = (name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const toUpdateCompletePaymentButton = () => {
    if (
      !values.domainOwnerFirstname ||
      !values.domainOwnerLastname ||
      !values.companyName ||
      !values.address ||
      !values.country ||
      !values.zipcode ||
      !values.phoneNumber
    ) {
      if (!hasErrorsInContactDetailsForm) {
        updateHasErrorsInContactDetailsForm(true);
      }

      return;
    }

    if (states.length > 0 && !values.state) {
      if (!hasErrorsInContactDetailsForm) {
        updateHasErrorsInContactDetailsForm(true);
      }

      return;
    }

    if (cities.length > 0 && !values.city) {
      if (!hasErrorsInContactDetailsForm) {
        updateHasErrorsInContactDetailsForm(true);
      }

      return;
    }

    if (hasErrorsInContactDetailsForm) {
      updateHasErrorsInContactDetailsForm(false);
    }
  };

  const handleInputBlur = (name: string) => {
    setErrors((prev) => ({
      ...prev,
      [name]: validate(name, values[name]),
    }));

    saveToLocalStorage(
      LOCAL_STORAGE_KEYS.EMAIL_ACCOUNT_INFRASTRUCTURE_CONTACT_DETAILS,
      values,
    );

    toUpdateCompletePaymentButton();
  };

  const handlePhoneInputChange = (value: string, data: any) => {
    setValues((prev) => ({ ...prev, phoneNumber: value }));
    setErrors((prev) => ({ ...prev, phoneNumber: '' }));

    if (data && data.countryCode !== countryCode) {
      setCountryCode(data.countryCode);
      phoneInputField.current?.focus();
    }
  };

  const handleCountryChange = (_country: any) => {
    setValues((prev) => ({
      ...prev,
      country: _country.name || '',
      state: '',
      city: '',
    }));

    setErrors((prev) => ({
      ...prev,
      country: '',
      state: '',
      city: '',
    }));
    setStates(State.getStatesOfCountry(_country.isoCode));
    setCities([]);
  };

  const handleStateChange = (_state: any) => {
    setValues((prev) => ({
      ...prev,
      state: _state.name || '',
      city: '',
    }));

    setErrors((prev) => ({
      ...prev,
      state: '',
      city: '',
    }));

    const selectedCountry = countries.find((c) => c.name === values.country);
    setCities(City.getCitiesOfState(selectedCountry.isoCode, _state.isoCode));
  };

  const handleCityChange = (cityName: string) => {
    setValues((prev) => ({ ...prev, city: cityName }));
    setErrors((prev) => ({ ...prev, city: '' }));
  };

  const handlePayNowButtonClick = () => {
    const payload = formatCreateEmailInfrastructurePayload(
      emailAccountInfrastructureServiceProvider,
      emailAccountInfrastructureForwardingDomain,
      emailAccountInfrastructureCart,
      values,
    );

    sendPurchaseDomainRequest(payload);
  };

  useEffect(() => {
    if (domainOwnerContactDetails && !isInitialized) {
      const initializeLocation = async () => {
        setValues({
          domainOwnerFirstname:
            domainOwnerContactDetails.domainOwnerFirstname || '',
          domainOwnerLastname:
            domainOwnerContactDetails.domainOwnerLastname || '',
          companyName: domainOwnerContactDetails.companyName || '',
          address: domainOwnerContactDetails.address || '',
          city: domainOwnerContactDetails.city || '',
          state: domainOwnerContactDetails.state || '',
          country: domainOwnerContactDetails.country || '',
          zipcode: domainOwnerContactDetails.zipcode || '',
          phoneNumber: domainOwnerContactDetails.phoneNumber || '',
        });

        const country = Country.getAllCountries().find(
          (c) => c.name === domainOwnerContactDetails.country,
        );
        if (country) {
          setStates(State.getStatesOfCountry(country.isoCode));

          const state = State.getStatesOfCountry(country.isoCode).find(
            (s) => s.name === domainOwnerContactDetails.state,
          );

          if (state) {
            setCities(City.getCitiesOfState(country.isoCode, state.isoCode));
          }
        }

        setIsInitialized(true);
      };

      initializeLocation();
    }
  }, [domainOwnerContactDetails, isInitialized]);

  useEffect(() => {
    const storedContactDetails = getFromLocalStorage(
      LOCAL_STORAGE_KEYS.EMAIL_ACCOUNT_INFRASTRUCTURE_CONTACT_DETAILS,
    );

    if (!storedContactDetails) {
      sendDomainOwnerContactDetailsRequest();
    }
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: purchaseDomainRequestStatus,
      onSuccess: () => {
        window.open(purchaseDomainResponse?.stripeCheckoutUrl || '', '_blank');
        resetPurchaseDomainRequest();
        setShowBillingSummaryModal(false);
        clearAllEmailAccountInfrastructureKeysFromLocalStorage();
        history.push('/email-accounts');
      },
      onFailed: () => {
        resetPurchaseDomainRequest();
        executeOnErrorWithErrorCheck({
          error: purchaseDomainRequestError,
          onError: () => {
            toaster.error(purchaseDomainRequestError?.message || '');
          },
        });
      },
    });
  }, [purchaseDomainRequestStatus]);

  useEffect(() => {
    if (isInitialized) {
      toUpdateCompletePaymentButton();
    }
  }, [isInitialized]);

  return (
    <>
      <form className="contact-form-container">
        <div className="form-content">
          <div className="notification-box">
            <div className="notification-content">
              <InfoCircle width={16} height={16} className="info-icon" />
              <p className="notification-message">
                These contact details will be used to purchase domain and email
                accounts.
              </p>
            </div>
          </div>

          <div className="contact-form">
            <div className="name-fields">
              <Input
                label="First Name"
                placeholder="John"
                value={values.domainOwnerFirstname}
                onChange={(value?: string) =>
                  handleInputChange('domainOwnerFirstname', value)
                }
                onBlur={() => handleInputBlur('domainOwnerFirstname')}
                variant={errors.domainOwnerFirstname && Input.Variant.Error}
                caption={errors.domainOwnerFirstname}
              />
              <Input
                label="Last Name"
                placeholder="Doe"
                value={values.domainOwnerLastname}
                onChange={(value?: string) =>
                  handleInputChange('domainOwnerLastname', value)
                }
                onBlur={() => handleInputBlur('domainOwnerLastname')}
                variant={errors.domainOwnerLastname && Input.Variant.Error}
                caption={errors.domainOwnerLastname}
              />
            </div>

            <Input
              label="Company Name"
              placeholder="Acme"
              value={values.companyName}
              onChange={(value?: string) =>
                handleInputChange('companyName', value)
              }
              onBlur={() => handleInputBlur('companyName')}
              variant={errors.companyName && Input.Variant.Error}
              caption={errors.companyName}
            />
          </div>

          <div className="address-field">
            <Input
              label="Address"
              placeholder="Street Number Name, Apartment, Unit"
              value={values.address}
              onChange={(value?: string) => handleInputChange('address', value)}
              onBlur={() => handleInputBlur('address')}
              variant={errors.address && Input.Variant.Error}
              caption={errors.address}
            />
          </div>

          <div className="country-state-fields">
            <div className="country-field">
              <InputLabel text="Country" place={InputLabel.Place.Before} />
              <select
                value={values.country || ''}
                onChange={(e) =>
                  handleCountryChange(
                    countries.find((c) => c.name === e.target.value),
                  )
                }
                onBlur={() => handleInputBlur('country')}
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.isoCode} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
              {errors.country && (
                <InputLabel
                  text={errors.country}
                  place={InputLabel.Place.After}
                />
              )}
            </div>

            <div className="state-field">
              <InputLabel
                text="State / Province"
                place={InputLabel.Place.Before}
              />

              <select
                value={values.state || ''}
                onChange={(e) =>
                  handleStateChange(
                    states.find((s) => s.name === e.target.value),
                  )
                }
                onBlur={() => handleInputBlur('state')}
              >
                <option value="">Select State / Province</option>
                {states.map((state) => (
                  <option key={state.isoCode} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
              {errors.state && (
                <InputLabel
                  text={errors.state}
                  place={InputLabel.Place.After}
                />
              )}
            </div>
          </div>

          <div className="city-zip-fields">
            <div className="city-field">
              <InputLabel text="City" place={InputLabel.Place.Before} />
              <select
                value={values.city || ''}
                onChange={(e) => {
                  handleCityChange(e.target.value);
                }}
                onBlur={() => handleInputBlur('city')}
              >
                <option value="">Select City</option>
                {cities.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
              {errors.city && (
                <InputLabel text={errors.city} place={InputLabel.Place.After} />
              )}
            </div>

            <Input
              label="ZIP / Postal Code"
              placeholder="E.g 10001"
              value={values.zipcode}
              onChange={(value?: string) => handleInputChange('zipcode', value)}
              onBlur={() => handleInputBlur('zipcode')}
              variant={errors.zipcode && Input.Variant.Error}
              caption={errors.zipcode}
              className="zip-field"
            />
          </div>

          <div className="phone-field">
            <div className="">
              <InputLabel text="Phone Number" place={InputLabel.Place.Before} />
              <PhoneInput
                country={countryCode}
                value={values.phoneNumber}
                onChange={handlePhoneInputChange}
                onBlur={() => handleInputBlur('phoneNumber')}
                containerClass={classNames([
                  'phone-form-input',
                  { 'input-error': errors.phoneNumber },
                ])}
                inputProps={{
                  tabIndex: 0,
                  name: 'phone',
                  ref: phoneInputField,
                }}
              />
              {errors.phoneNumber && (
                <InputLabel
                  text={errors.phoneNumber}
                  place={InputLabel.Place.After}
                />
              )}
            </div>
          </div>
        </div>
      </form>

      <BillingSummaryModal
        show={showBillingSummaryModal}
        onClose={() => setShowBillingSummaryModal(false)}
        onSubmit={handlePayNowButtonClick}
        emailAccountInfrastructureCart={emailAccountInfrastructureCart}
        isLoading={getIsRequestPending(purchaseDomainRequestStatus)}
      />
    </>
  );
};

export default ContactDetailsForm;
