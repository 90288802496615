/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tag } from '@saleshandy/icons';

import { Images } from '../../../../../../../../shared/app-constants';
import Switch, {
  Size,
} from '../../../../../../../../shared/design-system/components/atoms/switch';
import Icon from '../../../../../../../../shared/design-system/components/atoms/icon';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';
import {
  getEmailAccountInfo,
  getErrorMessage,
  getIsRequestPending,
} from '../../helpers';
import { Placement } from '../../../../../../../../shared/design-system/components/overlay';
import { executeOnRequestStatus } from '../../../../../../../../shared/utils/execute-on-request-status';
import toaster, { Theme } from '../../../../../../../../shared/toaster';
import { redirectWithRefresh } from '../../../../../../../../shared/utils';
import DisconnectEmailAccountModal from '../../../disconnect-email-account-modal';
import SMTPLabel from '../../../../utils/smtp-label';
import Button from '../../../../../../../../shared/design-system/components/atoms/button';
import {
  getEmailAccountsSearchQuery,
  removeEmailAccountsSearchQuery,
} from '../../../../utils/helpers';
import hasPermission from '../../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../../shared/utils/access-control/enums/permissions';
import ProspectTagsRow from '../../../../../../../../shared/design-system/components/molecules/prospect-tags-row';
import AddRemoveTag from '../../../../../../../../shared/components/add-remove-tag';
import {
  emailAccountTagsAssignRequest,
  getEmailAccountRequest,
  getEmailAccountTagsRequest,
} from '../../../../../../extra-actions';
import { RouteParams } from '../../types';
import { RootState } from '../../../../../../../../store/root-reducer';
import { resetEmailAccountTagsRequest } from '../../../../../../email-accounts-slice';
import { EmailAccountStatus } from '../../../../../../types/email-account';

const UpdateEmailAccountHeader = ({
  emailAccountId,
  emailAccount,
  authUrl,
  connectEmailAccountHandler,
  updateEmailAccountHandler,
  sendDisconnectEmailAccountRequest,
  disconnectEmailAccountRequestStatus,
  disconnectEmailAccountRequestError,
  connectEmailAccountRequestStatus,
  connectEmailAccountRequestError,
  sendGetSmtpImapAccountDetailsRequest,
  isEditEmailSettingsButtonLoading,
  showSmtpModalOnDisconnect,
}) => {
  const { t } = useTranslation();
  const tagRef = useRef(null);
  const history = useHistory();
  const params = useParams<RouteParams>();
  const { hashId } = params;
  const dispatch = useDispatch();

  const addTagsRequestStatus = useSelector(
    (state: RootState) =>
      state.emailAccount.emailAccountTagsAssignRequest.status,
  );
  const emailAccountTagsList = useSelector(
    (state: RootState) => state.emailAccount.emailAccountTagsList,
  );

  const selectedTags = [{ tags: emailAccountTagsList }];
  const emailAccountInfo = getEmailAccountInfo(emailAccount);
  const { name, email, accountType, isConnected } = emailAccountInfo;

  //* State */
  const [
    showDisconnectEmailAccountModal,
    setShowDisconnectEmailAccountModal,
  ] = useState<boolean>(false);
  const [emailAccountsSearchQuery, setEmailAccountsSearchQuery] = useState('');

  const [showModal, setShowModal] = useState(false);

  //* End State */

  const goBack = () => {
    history.push({
      pathname: '/email-accounts',
      search: emailAccountsSearchQuery,
    });
  };

  const showDisconnectEmailAccountModalHandler = () => {
    setShowDisconnectEmailAccountModal(true);
  };

  const hideDisconnectEmailAccountModalHandler = () => {
    setShowDisconnectEmailAccountModal(false);
  };

  const handleOpenTagModal = () => {
    setShowModal((prev) => !prev);
    tagRef?.current?.openAddTagModal();
  };

  const onDisconnectEmailAccountSubmit = () => {
    sendDisconnectEmailAccountRequest(emailAccountId);
  };

  const onEditEmailSettings = () => {
    sendGetSmtpImapAccountDetailsRequest(emailAccountId);
  };

  const isDisconnectEmailAccountRequestPending = getIsRequestPending(
    disconnectEmailAccountRequestStatus,
  );

  const renderAddTag = () =>
    hasPermission(Permissions.SEQUENCE_PROSPECT_UPDATE_TAGS) ? (
      <div
        {...accessibleOnClick(handleOpenTagModal)}
        className="prospect-add-tag regular-2"
      >
        {t('messages.add_tag')}
      </div>
    ) : (
      <></>
    );

  const handleAddTag = async (tags) => {
    const payload = {
      emailAccountIds: [emailAccountId],
      ids: [],
      newTags: tags.map((tag) => tag.name),
    };

    await dispatch(emailAccountTagsAssignRequest(payload));
    dispatch(getEmailAccountRequest(hashId));
    setShowModal(false);
  };

  useEffect(() => {
    const query = getEmailAccountsSearchQuery();

    setEmailAccountsSearchQuery(query);

    const removeQueryTimeout = setTimeout(() => {
      removeEmailAccountsSearchQuery();
    }, 1000);

    return () => clearTimeout(removeQueryTimeout);
  }, []);

  useEffect(() => {
    if (showDisconnectEmailAccountModal) {
      executeOnRequestStatus({
        status: disconnectEmailAccountRequestStatus,
        onSuccess: () => {
          hideDisconnectEmailAccountModalHandler();
          if (showSmtpModalOnDisconnect) {
            onEditEmailSettings();
          }
          toaster.success('Email Account Disconnected', { theme: Theme.New });
        },
        onFailed: () => {
          toaster.error(getErrorMessage(disconnectEmailAccountRequestError), {
            theme: Theme.New,
          });
        },
      });
    }
  }, [disconnectEmailAccountRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: connectEmailAccountRequestStatus,
      onSuccess: () => {
        redirectWithRefresh(authUrl);
      },
      onFailed: () => {
        toaster.error(getErrorMessage(connectEmailAccountRequestError), {
          theme: Theme.New,
        });
      },
    });
  }, [connectEmailAccountRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: addTagsRequestStatus,
      onSuccess: () => {
        toaster.success('Tags applied successfully', { theme: Theme.New });

        dispatch(resetEmailAccountTagsRequest());
      },
    });
  }, [addTagsRequestStatus]);

  useEffect(() => {
    if (showModal) {
      // Get Tags List
      dispatch(getEmailAccountTagsRequest());
    }
  }, [showModal]);

  useEffect(() => {
    if (showModal) {
      // Get Tags List
      dispatch(getEmailAccountTagsRequest());
    }
  }, [showModal]);

  const switchConfig = (() => {
    if (isConnected === EmailAccountStatus.Active) {
      return {
        label: 'Connected',
        className: 'connected',
        tooltip: 'Disconnect',
        handler: showDisconnectEmailAccountModalHandler,
      };
    }
    if (isConnected === EmailAccountStatus.InActive) {
      return {
        label: 'Disconnected',
        className: 'disconnected',
        tooltip: 'Connect',
        handler: connectEmailAccountHandler,
      };
    }
    return {
      label: 'Paused',
      className: 'paused',
      tooltip: 'Activate',
      handler: () =>
        updateEmailAccountHandler(EmailAccountStatus.Active, emailAccount),
    };
  })();

  return (
    <>
      <div className="update-email-header">
        <div className="update-email-header-content">
          <div className="update-email-header-left">
            <div className="back-btn">
              <img
                src={Images.ChevronLeft}
                alt="back"
                {...accessibleOnClick(goBack)}
              />
            </div>
            <div className="email-info">
              <h1>{name}</h1>

              <div className="smtp-email-header">
                <p>
                  {email}
                  {email && emailAccount?.type !== 3 ? (
                    <Icon
                      className="email-account-type"
                      identifier={accountType}
                    />
                  ) : (
                    <SMTPLabel className="smtp-label" />
                  )}
                </p>

                {emailAccount?.type === 3 && (
                  <Button
                    variant={Button.Variant.Primary}
                    theme={Button.Theme.Ghost}
                    className="edit-email-settings-btn"
                    onClick={onEditEmailSettings}
                    isLoading={isEditEmailSettingsButtonLoading}
                    disabled={isEditEmailSettingsButtonLoading}
                  >
                    {t('labels.edit_email_settings')}
                  </Button>
                )}
              </div>
            </div>
          </div>
          {hasPermission(Permissions.EMAIL_ACCOUNT_DISCONNECT) && (
            <div className="update-email-header-right">
              <p
                className={`connect-disconnect-label ${switchConfig.className}`}
              >
                {switchConfig.label}
              </p>
              <Switch
                checked={isConnected === EmailAccountStatus.Active}
                size={Size.Small}
                className="email-switch connected"
                tooltip={switchConfig.tooltip}
                placement={Placement.Bottom}
                onChange={switchConfig.handler}
              />
            </div>
          )}
        </div>

        <div className="detail-page-tag">
          <div className="tag-row-container">
            {emailAccount?.tags?.length ? <Tag className="tag-icon" /> : ''}
            <ProspectTagsRow
              tags={emailAccount?.tags || []}
              renderAddTag={renderAddTag}
              maxTags={2}
              isPosition={true}
              emptyPlaceString=""
            />
          </div>
        </div>
      </div>

      {showDisconnectEmailAccountModal && (
        <DisconnectEmailAccountModal
          show={showDisconnectEmailAccountModal}
          onSubmit={onDisconnectEmailAccountSubmit}
          onClose={hideDisconnectEmailAccountModalHandler}
          isRequestPending={isDisconnectEmailAccountRequestPending}
        />
      )}

      <AddRemoveTag
        ref={tagRef}
        handleAddTag={handleAddTag}
        handleRemoveTag={() => {}}
        isSubmitLoading={getIsRequestPending(addTagsRequestStatus)}
        selectedRecords={selectedTags}
        tags={emailAccountTagsList}
        isAddTagModal={true}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </>
  );
};

export default UpdateEmailAccountHeader;
