import React from 'react';
import { Link } from 'react-router-dom';

import { supportUrls } from '../../utils/urls';
import {
  CustomDomainScore,
  DailySendingQuotaScore,
  DmarcScore,
  EmailAgeScore,
  SendingIntervalScore,
  SpfScore,
} from './enums';

export const getSPFDescription = (score: number) =>
  score === SpfScore.min ? (
    <p>
      Uh-oh! The SPF record is not set for this email account. It's critically
      important, so we recommend urgently setting it up, as mentioned{' '}
      <a href={supportUrls.spfBlog} target="_blank" rel="noreferrer">
        here
      </a>
      .
    </p>
  ) : (
    <p>Great news! Your SPF record is set up correctly.</p>
  );

export const getCTDDescription = (score: number) =>
  score === CustomDomainScore.min ? (
    <p>
      Oops! The custom tracking domain is not set.{' '}
      <a href={supportUrls.ctdBLog} target="_blank" rel="noreferrer">
        Learn how
      </a>{' '}
      to set up a custom tracking domain & set it up{' '}
      <Link to="/settings/custom-domain">here</Link>.
    </p>
  ) : (
    <p>Congratulations! Your custom tracking domain is set up correctly.</p>
  );

export const getDSQDescription = (score: number) => {
  if (score === DailySendingQuotaScore.min) {
    return (
      <p>
        Your email account has a high daily sending quota, which can increase
        the risk of hitting spam filters. We strongly recommend it to keep below
        50.
      </p>
    );
  }

  if (score === DailySendingQuotaScore.mid) {
    return (
      <p>
        Your email account has a moderate daily sending quota. It's important to
        follow proper email-sending practices to avoid hitting your quota and
        maintain a good sender reputation. Consider segmenting your email list
        and sending emails in batches to stay within your sending quota.
      </p>
    );
  }

  return (
    <p>
      Excellent! Your email account has an ideal daily sending quota, a good
      practice to prevent spam issues.
    </p>
  );
};

export const getDMARCDescription = (score: number) =>
  score === DmarcScore.min ? (
    <p>
      The DMARC record is not set. It helps prevent email spoofing, so we
      suggest setting it up, as mentioned{' '}
      <a href={supportUrls.dmarcBlog} target="_blank" rel="noreferrer">
        here
      </a>
      .
    </p>
  ) : (
    <p>Excellent! Your DMARC record is set up correctly.</p>
  );

export const getEmailAgeDescription = (score: number) => {
  if (score === EmailAgeScore.min) {
    return (
      <p>
        This email account is less than 3 months old. Please send fewer emails,
        enable ramp-up & use email warm-up for this email account.
      </p>
    );
  }

  if (score === EmailAgeScore.mid) {
    return (
      <p>
        This email account is between 3-6 months old. It's still relatively new,
        but with proper email sending practices, you can improve your sender
        reputation and email deliverability. We recommend you to keep the daily
        sending quota between 25-75 to ensure your email account is not flagged
        for spam. We also suggest you to use email warm-up to maintain a optimum
        deliverability.
      </p>
    );
  }

  return <p>Great! The email account is more than 6 months old.</p>;
};

export const getSendingIntervalDescription = (score: number) => {
  if (score === SendingIntervalScore.min) {
    return (
      <p>
        Uh-oh! The time interval between two emails is too short. It can affect
        the deliverability; therefore, we suggest you increase it to 200
        seconds.
      </p>
    );
  }

  if (score === SendingIntervalScore.mid) {
    return (
      <p>
        Your email account is sending emails at a moderate pace, but it's still
        important to follow proper email-sending practices to maintain a good
        sender reputation. Consider increasing the interval beyond 180 seconds
        to further improve deliverability and prevent spam issues.
      </p>
    );
  }

  return (
    <p>
      Excellent! The email-sending speed is reasonable, which can help prevent
      spam issues.
    </p>
  );
};
