/* eslint-disable import/no-cycle */
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { debounce } from 'lodash';
import { AuthHelper, getToken } from './shared/utils';
import { ExceptionTypes, ResponseErrorWithHandled } from './shared/types';
import { showValidationErrorNotification } from './shared/utils/errors';
import history from './shared/history';
import { planError } from './shared/utils/errors/plan-permission-error/plan-error';
import { showGeneralErrorToaster } from './shared/utils/errors/system-error-toaster';
import { SystemErrors } from './shared/enums/system-errors';
import {
  clearAgencyClientRedirectURLOnLogout,
  getAgencyClientRedirectURLOnLogout,
} from './components/auth/components/agency-client-login/helper';
import { ApiStatusCode } from './shared/enums/status-codes';

axiosRetry(axios, {
  retries: 3, // Number of retries
  retryCondition: (error) => error.response.status === ApiStatusCode.BadGateway,
  retryDelay: () => 300, // Delay between each retry (in milliseconds)
  onMaxRetryTimesExceeded: (error) => {
    showGeneralErrorToaster(error.message); // TODO: Message needs to be updated
  },
});

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

const isCustomError = (err: any): boolean => !!err.response?.data?.type;

const debounceSystemError = debounce((errorMessage) => {
  showGeneralErrorToaster(errorMessage);
}, 500);

const handleSystemError = (errorMessage?: string) => {
  debounceSystemError(errorMessage || SystemErrors.InternalServerError);
};

instance.interceptors.response.use(
  (response) => response.data,
  (err): Promise<ResponseErrorWithHandled> => {
    let error: ResponseErrorWithHandled;
    if (
      err?.response?.status === ApiStatusCode.GatewayTimeout ||
      err?.response?.status === ApiStatusCode.Timeout
    ) {
      error = {
        error: true,
        type: ExceptionTypes.General,
        message: 'This is taking longer than expected. Please try again soon.',
        isHandled: true,
      };

      handleSystemError(error.message);
      return Promise.reject(error);
    }
    if (err?.response?.status === ApiStatusCode.Unknown) {
      error = {
        error: true,
        type: ExceptionTypes.General,
        message:
          "We couldn't complete your request. Please try again later. If the problem persists, check your internet connection or update your browser.",
        isHandled: true,
      };

      handleSystemError(error.message);
      return Promise.reject(error);
    }
    if (err?.response?.status === ApiStatusCode.RateLimitExceeded) {
      error = {
        error: true,
        type: ExceptionTypes.System,
        message:
          'You have reached the limit of allowed actions. Please wait for some time before trying again.',
        isHandled: true,
      };

      handleSystemError(error.message);
      return Promise.reject(error);
    }

    // ** Handle Custom API Cancellation
    if (axios?.isCancel(err)) {
      // * Case - Only message passed to cancel token to show custom message in toaster
      if (typeof err?.message === 'string') {
        error = {
          error: true,
          type: ExceptionTypes.System,
          message: err?.message || 'Request was cancelled',
          isHandled: true,
        };
      } else {
        // * Case - Passed error message object with different keys
        const type = err?.message?.type || ExceptionTypes.System;
        const message = err?.message?.message || 'Request was cancelled';
        error = { ...err, type, message, isHandled: true };
      }
      if (error?.type !== ExceptionTypes.Silent) {
        handleSystemError(error.message);
      }
      return Promise.reject(error);
    }

    // to prevent to throw error get from backend for suspended user, will use custom error message at login-form
    if (err?.response?.data?.code === 1002) {
      error = { ...err.response.data, isHandled: true };
      return Promise.reject(error);
    }

    if (isCustomError(err)) {
      error = { ...err.response.data, isHandled: false };

      let interval;
      // eslint-disable-next-line default-case
      switch (error.type) {
        case ExceptionTypes.Auth:
          AuthHelper.logout({
            fallbackTo: history.location,
            redirect: true,
            pathName: getAgencyClientRedirectURLOnLogout() || '/login',
          });
          interval = setInterval(() => {
            clearAgencyClientRedirectURLOnLogout();

            clearInterval(interval);
          }, 1000);
          break;

        case ExceptionTypes.System:
          handleSystemError();
          error.isHandled = true;
          break;

        case ExceptionTypes.Validation: {
          showValidationErrorNotification(error.messages);
          error.isHandled = true;
          break;
        }
        case ExceptionTypes.PlanPermission: {
          planError(error.code, error.message);
          error.isHandled = true;
          break;
        }
        case ExceptionTypes.General: {
          handleSystemError(error.message);
          error.isHandled = true;
          break;
        }
        case ExceptionTypes.Silent: {
          error.isHandled = true;
          break;
        }
      }
    } else {
      error = {
        error: true,
        type: ExceptionTypes.System,
        message: err.message,
        isHandled: true,
      };

      handleSystemError(error.message);
    }

    return Promise.reject(error);
  },
);

export default instance;
