import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import Suspense from '../../shared/components/suspense';
import LeadListing from './components/lead-listing';
import LeadsSidebar from './components/leads-filter';
import TotalCredits from './components/total-credits/total-credits';
import { BULK_ACTION_TIMEOUT, IProps, LeadFinderTabKey } from './type';
import RecentSearch from './components/recent-search';
import SearchingEmpty from './components/searching-empty';
import LeadsHeaderSkeleton from './components/leadsSkeleton/leadsHeaderSkeleton';
import { useLeadFinder } from './components/hooks/useLeadFinder';
import { useLeadBulkAction } from './components/hooks/useLeadBulkReveal';
import NoSearchResult from './components/no-search-result';
import { getLeadsActiveTab } from './helpers/helper';
import IndividualLeadsSkeleton from './components/leadsSkeleton/individualLeadsSkeleton';
import { RootState } from '../../store/root-reducer';
import NoSearchResultIcon from '../../shared/components/images/no-search-result-icon';
import { getUserTrackingId } from '../../shared/utils/user-details';
import { AnalyticsEvents } from '../../shared/enums/analytics';
import WatchVideoModal from '../home/components/watch-video-modal';
import { executeOnRequestStatus } from '../../shared/utils';
import { finishOnboardingRequest } from '../sequence/extra-actions';
import {
  OnboardingSteps,
  ProfileProgressSteps,
  UserSettingCode,
} from '../../shared/types/user-setting';
import {
  getUserSettingsRequest,
  updateApplicationState,
} from '../home/home-slice';
import { handleOnboarding } from '../../shared/utils/handle-onboarding-routing';
import updateProfileProgressRequest from '../../shared/components/getting-started-onboarding/extra-actions';
import { useLeadApiState } from './components/hooks/useLeadApiState';
import { useSavedLeadFilter } from './components/hooks/useLeadSavedFilter';
import { usePageVisibility } from '../../shared/utils/hooks';
import { toaster } from '@saleshandy/design-system';
import { getPostLoadMetaRequest } from '../home/extra-actions';
import { getLeads, getSavedLeads } from './helpers/leads.api';

const Leads: React.FC<IProps> = () => {
  const location: any = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeTabKey, setActiveTabKey] = useState(getLeadsActiveTab(location));
  const [selectedLeads, setSelectedLeads] = useState([]);
  const { savedFilters, setSavedFilters } = useSavedLeadFilter(activeTabKey);
  const {
    isLoading,
    leadsData,
    apiParam,
    handlePagination,
    handleRecentSearch,
    handleGeneratePayload,
    handleAppendLead,
    setLeadsData,
    handleRouteChange,
  } = useLeadFinder(activeTabKey, setSelectedLeads, setSavedFilters);

  const {
    leadTags,
    isLeadTagsLoading,
    fetchLeadTags,
  } = useLeadApiState();

  useEffect(() => {
    if (activeTabKey === LeadFinderTabKey.SAVED_TAB) {
      fetchLeadTags(true);
    }
  }, [activeTabKey]);

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const loginUserEmail = useSelector((state: RootState) => state?.home?.email);
  const getUserSettingsRequestStatus = useSelector(
    (state: RootState) => state?.home.getUserSettingsRequest.status,
  );

  const meta = useSelector((state: RootState) => state?.home.meta);
  const profileProgress = useSelector(
    (state: RootState) => state?.home.profileProgress,
  );
  const firstName = useSelector((state: RootState) => state?.home.firstName);
  const userUrls =
    JSON.parse(localStorage.getItem('storedUrls'))?.[loginUserEmail] || [];
  const [storedUrls, setStoredUrls] = useState(userUrls || []);
  const [
    isWatchVideoModalVisible,
    setIsWatchVideoModalVisible,
  ] = useState<boolean>(false);

  const {
    handleCheckUnCheckSingleLead,
    handleSelectAllLead,
    isBulkRevealing,
    setIsBulkRevealing,
    disabledLeads,
    setDisabledLeads,
    isSelectAllIndeterminate,
    selectedAllLeads,
    isSelectingAllLeads,
    setIsSelectingAllLeads,
    isSelectedAllLeads,
    setIsSelectedAllLeads,
    handleClearSelectedAllBulkAction,
    handleSelectAllBulkActionLead,
    deSelectedLeads,
    leadCredits,
    topPeopleLimit,
    leadCreditsState,
    setLeadsCredits,
    handleResetStateAfterAnyAction,
  } = useLeadBulkAction(leadsData, selectedLeads, setSelectedLeads, activeTabKey);

  const renderComponents = () =>
    Array.from({ length: 4 }, (_, index) => (
      <IndividualLeadsSkeleton key={index} />
    ));

  const onCompleteOnboardingProcess = () => {
    if (!isEmpty(meta)) {
      const onboardingStep = meta.find(
        (setting) => setting.code === UserSettingCode.Onboarding,
      );

      if (onboardingStep?.value === OnboardingSteps.Step2) {
        dispatch(finishOnboardingRequest());
        dispatch(updateApplicationState(true));
        setIsWatchVideoModalVisible(true);

        const currentPath = location.pathname + location.search;
        handleOnboarding(meta, currentPath, '', true);
        setTimeout(() => {
          history.push({
            search: '',
          });
        }, 2000);
      }
    }
  };

  const onVideoModalClose = () => {
    setIsWatchVideoModalVisible(false);
    dispatch(getUserSettingsRequest());
  };

  const updateWatchVideoProfileProgressStep = () => {
    if (profileProgress) {
      const watchVideoStep = profileProgress.find(
        (step) =>
          step.profileProgressStep.stepName === ProfileProgressSteps.WatchVideo,
      );
      if (!watchVideoStep.isCompleted) {
        updateProfileProgressRequest({
          step: ProfileProgressSteps.WatchVideo,
          isCompleted: true,
        });
      }
    }
  };

  useEffect(() => {
    // Lead Finder Page Loaded Event
    window.analytics?.track({
      userId: getUserTrackingId(),
      event: AnalyticsEvents.LoadedAPage,
      properties: {
        url: document.location.href,
      },
    });
  }, []);

  const isPageVisible = usePageVisibility();
  const timerIdRef = useRef(null);
  const [isPollingEnabled, setIsPollingEnabled] = useState(false);
  const [isFirstPollRequest, setIsFirstPollRequest] = useState(true);
  const [loadingGetInfoLeads, setLoadingGetInfoLeads] = useState<Object>({});

  const handleFetchLeadsAfterBulkActions = async (enablePolling = false) => {
    try {
      let res: any = {};
      if (activeTabKey === LeadFinderTabKey.PEOPLE_TAB) {
        res = await getLeads(apiParam);
      } else {
        res = await getSavedLeads(apiParam);
      }
      if (res?.payload) {
        setLeadsData(res.payload);
        setIsBulkRevealing(false);
        const revealingLeads = res.payload.profiles.filter(lead => lead.isRevealing);
        revealingLeads.forEach(lead => {
          setLoadingGetInfoLeads((prev) => ({
            ...prev,
            [lead.id]: true,
          }));
        });
        const revealedLeads = res.payload.profiles.filter(lead => lead.isRevealed);
        revealedLeads.forEach(lead => {
          setLoadingGetInfoLeads((prev) => ({
            ...prev,
            [lead.id]: false,
          }));
        });
        dispatch(getPostLoadMetaRequest());
        if (enablePolling) {
          setIsPollingEnabled(true);
          handleResetStateAfterAnyAction();
        }
      }
    } catch (e: any) {
      toaster.error(e?.message);
    }
  };

  useEffect(() => {
    const startPolling = () => {
      timerIdRef.current = setInterval(() => {
        const shouldContinuePolling = leadsData?.profiles?.some(
          (ele) => ele.isRevealing,
        );
        console.log('shouldContinuePolling', shouldContinuePolling);
        if (!shouldContinuePolling) {
          setIsPollingEnabled(false);
          console.log('Polling failed. Stopped polling.');
        } else {
          console.log('Polling...', isFirstPollRequest);
          setIsPollingEnabled(true);
          handleFetchLeadsAfterBulkActions();
          if (isFirstPollRequest) {
            handleResetStateAfterAnyAction();
            setIsFirstPollRequest(false);
          }
        }
      }, BULK_ACTION_TIMEOUT);
    };

    const stopPolling = () => {
      setIsFirstPollRequest(true);
      clearInterval(timerIdRef.current);
    };

    if (isPageVisible && isPollingEnabled) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [isPageVisible, isPollingEnabled, leadsData]);


  useEffect(() => {
    setStoredUrls(userUrls);
  }, [location]);

  useEffect(() => {
    const { hash, search } = location;
    const searchParams = new URLSearchParams(search);
    if (hash.includes(LeadFinderTabKey.SAVED_TAB)) {
      setActiveTabKey(LeadFinderTabKey.SAVED_TAB);
    } else {
      setActiveTabKey(LeadFinderTabKey.PEOPLE_TAB);
    }

    // Check if no #<tabKey> is present in the current route
    if (
      !hash ||
      !(
        hash.includes(LeadFinderTabKey.SAVED_TAB) ||
        hash.includes(LeadFinderTabKey.PEOPLE_TAB)
      )
    ) {
      // Redirect to the same path with #people added
      history.replace({
        search: searchParams.toString(),
        hash: LeadFinderTabKey.PEOPLE_TAB,
      });
    }
  }, [location, history]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getUserSettingsRequestStatus,
      onSuccess: onCompleteOnboardingProcess,
    });
  }, [getUserSettingsRequestStatus]);

  useEffect(() => {
    setLeadsCredits(
      Number(leadCreditsState?.home?.getPostLoadMetaResponse
        ?.accountUsageQuotaRemaining?.['LEAD.REVEAL']),
    );
  }, [leadCreditsState, leadsData]);


  useEffect(() => {
    if (activeTabKey === LeadFinderTabKey.SAVED_TAB && isSelectedAllLeads) {
      const filteredLeads = leadsData?.profiles.filter(l => disabledLeads.filter(dl => dl.id !== l.id));
      setSelectedLeads(filteredLeads);
    }
  }, [leadsData, isSelectedAllLeads, disabledLeads]);

  return (
    <>
      <Container fluid className="leads-container">
        <ErrorBoundaryWrapper>
          <Suspense>
            <Row>
              <Col>
                <Row className="leads-content">
                  <LeadsSidebar
                    {...{
                      isSidebarExpanded,
                      setIsSidebarExpanded,
                      handleRecentSearch,
                      handleGeneratePayload,
                      setActiveTabKey,
                      activeTabKey,
                      handlePagination,
                      setLeadsData,
                      setSelectedLeads,
                      handleRouteChange,
                      leadTags,
                      isLeadTagsLoading,
                      fetchLeadTags,
                      handleResetStateAfterAnyAction,
                      savedFilters,
                      setSavedFilters,
                    }}
                  />
                  <div
                    className={`${!isSidebarExpanded
                      ? 'collpase-leads-component'
                      : 'leads-components'
                      } collpase-leads-component`}
                  >
                    {!isLoading && (
                      <TotalCredits
                        {...{
                          topPeopleLimit,
                          leadCredits,
                          handleSelectAllLead,
                          handleCheckUnCheckSingleLead,
                          selectedLeads,
                          leadsData,
                          setIsBulkRevealing,
                          isBulkRevealing,
                          apiParam,
                          setLeadsData,
                          activeTabKey,
                          setSelectedLeads,
                          isSelectAllIndeterminate,
                          disabledLeads,
                          isSelectingAllLeads,
                          setIsSelectingAllLeads,
                          isSelectedAllLeads,
                          setIsSelectedAllLeads,
                          handleClearSelectedAllBulkAction,
                          handleSelectAllBulkActionLead,
                          deSelectedLeads,
                          leadTags,
                          isLeadTagsLoading,
                          fetchLeadTags,
                          handleResetStateAfterAnyAction,
                          savedFilters,
                          setSavedFilters,
                          handleFetchLeadsAfterBulkActions,
                        }}
                      />
                    )}
                    {userUrls?.length === 0 && !location?.search && (
                      <SearchingEmpty />
                    )}

                    {userUrls &&
                      userUrls?.length > 0 &&
                      !location?.search &&
                      activeTabKey === LeadFinderTabKey?.PEOPLE_TAB && (
                        <RecentSearch
                          {...{ storedUrls, setStoredUrls, loginUserEmail }}
                        />
                      )}
                    {!isLoading &&
                      leadsData &&
                      location?.search &&
                      leadsData?.profiles?.length === 0 &&
                      leadsData?.pagination?.total === 0 && (
                        <div className="no-search-lead">
                          <NoSearchResult
                            description={
                              <div>
                                Results matching this query could not be
                                displayed. Please try refining your search{' '}
                                <br /> or clearing some of your filters.
                              </div>
                            }
                            title="No results found"
                          >
                            <NoSearchResultIcon />
                          </NoSearchResult>
                        </div>
                      )}
                    {isLoading && location?.search && (
                      <LeadsHeaderSkeleton activeTabKey={activeTabKey} />
                    )}
                    {isLoading && location?.search && renderComponents()}

                    {leadsData?.profiles &&
                      leadsData?.profiles?.length > 0 &&
                      location?.search && (
                        <>
                          <LeadListing
                            {...{
                              topPeopleLimit,
                              leadCredits,
                              isLoading,
                              leadsData,
                              handlePagination,
                              handleCheckUnCheckSingleLead,
                              selectedLeads,
                              handleAppendLead,
                              isBulkRevealing,
                              apiParam,
                              setLeadsData,
                              activeTabKey,
                              setSelectedLeads,
                              disabledLeads,
                              setDisabledLeads,
                              handleRouteChange,
                              setLeadsDataState: setLeadsData,
                              deSelectedLeads,
                              isSelectedAllLeads,
                              handleResetStateAfterAnyAction,
                              loadingGetInfoLeads,
                              setLoadingGetInfoLeads,
                              handleFetchLeadsAfterBulkActions,
                              isFirstPollRequest,
                            }}
                          />
                        </>
                      )}
                  </div>
                </Row>
              </Col>
            </Row>
          </Suspense>
        </ErrorBoundaryWrapper>
      </Container>

      {isWatchVideoModalVisible && (
        <WatchVideoModal
          show={isWatchVideoModalVisible}
          isGettingStartedVideModalShowThroughIcon={false}
          firstName={firstName}
          onClose={() => {
            onVideoModalClose();
          }}
          updateWatchVideoProfileProgressStep={
            updateWatchVideoProfileProgressStep
          }
          isLeadFinderOnboarding={true}
        />
      )}
    </>
  );
};

export default Leads;
