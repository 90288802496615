import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@saleshandy/design-system';
import { ChevronDown, ChevronUp } from '@saleshandy/icons';
import Transfer from '../../../../../shared/design-system/components/transfer';
import { Placement } from '../../../../../shared/design-system/components/overlay';
import Checkbox from '../../../../../shared/design-system/components/atoms/checkbox';
import { Domain } from '../../../types/create-email-infrastructure';
import Input from '../../../../../shared/design-system/components/input';

type IProps = {
  domainsInCart: Domain[];
  user: string;
  setUser: React.Dispatch<React.SetStateAction<string>>;
  selectedDomains: string[];
  setSelectedDomains: React.Dispatch<React.SetStateAction<string[]>>;
};

const EmailAddressComponent: React.FC<IProps> = ({
  domainsInCart,
  user,
  setUser,
  selectedDomains,
  setSelectedDomains,
}) => {
  const [show, setShow] = useState(false);

  const options = useMemo(
    () =>
      domainsInCart.map((domain) => ({
        key: domain.name.toString(),
        name: domain.name,
        price: domain.price,
      })),
    [domainsInCart],
  );

  const renderSelectedDomains = () => {
    if (domainsInCart.length === 1) {
      return `@${domainsInCart[0].name}`;
    }

    if (selectedDomains.length === domainsInCart.length) {
      return '@All Domains';
    }

    if (selectedDomains.length > 0) {
      return `@${selectedDomains.length} Domains`;
    }

    return (
      <span className="email-domains-select-no-domains">Select Domains</span>
    );
  };

  useEffect(() => {
    if (domainsInCart.length > 0) {
      setSelectedDomains(domainsInCart.map(({ name }) => name));
    }
  }, [domainsInCart, setSelectedDomains]);

  return (
    <div className="create-email-accounts-email-address-component">
      <Input
        label="Email Address"
        placeholder="john.doe"
        onChange={(
          value?: string,
          event?: React.ChangeEvent<HTMLInputElement>,
        ) => setUser(event.target.value)}
        className="email-with-select-domain"
        value={user}
      />
      <Transfer<Domain>
        show={show}
        onToggle={(value) => setShow(value)}
        className="email-filter-dropdown email-filter"
        showSearch
        searchPlaceholder="Search"
        multiSelect={true}
        options={options}
        showOptionsSeparator={true}
        optionRenderer={(option) => (
          <span className="sequence-name-item">{option.name}</span>
        )}
        filterOption={(value, option) =>
          option?.name?.toLowerCase().includes(value.toLowerCase())
        }
        selectedOptionKey={selectedDomains.map((key) => key.toString())}
        onChange={(selectedOptions) => {
          setSelectedDomains(selectedOptions.map((option) => option.name));
        }}
        placement={Placement.BottomEnd}
        footer={
          <>
            <Checkbox
              checked={selectedDomains.length === domainsInCart.length}
              intermediate={selectedDomains.length !== 0}
              label="Select All"
              onChange={(checked) => {
                if (checked) {
                  setSelectedDomains(domainsInCart.map(({ name }) => name));
                } else {
                  setSelectedDomains([]);
                }
              }}
            />
            <Button
              onClick={() => {
                setShow(false);
              }}
              disabled={selectedDomains.length === 0}
            >
              Apply
            </Button>
          </>
        }
      >
        <Button
          className="email-domains-select-btn"
          variant="tertiary"
          onClick={() => setShow(!show)}
          disabled={domainsInCart.length <= 1}
        >
          <div className="email-domains-select-content">
            {renderSelectedDomains()}
          </div>
          {domainsInCart.length > 1 && (
            <div className="email-domains-select-icon">
              {show ? <ChevronUp /> : <ChevronDown />}
            </div>
          )}
        </Button>
      </Transfer>
    </div>
  );
};

export default EmailAddressComponent;
