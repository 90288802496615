import { EmailAccountInfrastructureServiceProvider } from '../../../enums/create-email-infrastructure';
import {
  CreateEmailAccountInfrastructureCart,
  DomainOwnerContactDetails,
} from '../../../types/create-email-infrastructure';
import { PurchaseDomainRequestPayload } from '../../../types/request-payload';

export const formatCreateEmailInfrastructurePayload = (
  emailAccountInfrastructureServiceProvider: EmailAccountInfrastructureServiceProvider,
  emailAccountInfrastructureForwardingDomain: string,
  emailAccountInfrastructureCart: CreateEmailAccountInfrastructureCart,
  domainOwnerContactDetails: DomainOwnerContactDetails,
): PurchaseDomainRequestPayload => ({
  ...domainOwnerContactDetails,
  emailServiceProvider: emailAccountInfrastructureServiceProvider,
  domains: emailAccountInfrastructureCart.domains.map((domain) => ({
    name: domain.name,
    redirectUrl: emailAccountInfrastructureForwardingDomain,
    mailboxes: emailAccountInfrastructureCart.emailAccounts
      .filter((emailAccount) => emailAccount.email.endsWith(`@${domain.name}`))
      .map((emailAccount) => ({
        firstname: emailAccount.firstName,
        lastname: emailAccount.lastName,
        email: emailAccount.email,
        profilePicId: emailAccount.profile?.id || null,
      })),
  })),
});
