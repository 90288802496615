import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/root-reducer';

import CreateConnectEmailAccount from './create-connect-email-account';

import { EmailAccountMethod } from '../../enums/email-account';
import { EmailAccountInfrastructureServiceProvider } from '../../enums/create-email-infrastructure';
import { ReactivateResumeSubscriptionRequest } from '../../../settings/components/billing-subscription/types';

import {
  connectEmailAccountRequest,
  setEmailAccountInfrastructureServiceProvider,
} from '../../email-accounts-slice';
import {
  getConnectedUsersAndEmailAccountsRequest,
  reactivateSubscriptionRequest,
} from '../../../settings/components/billing-subscription/extra-actions';
import { resetConnectedUsersAndEmailAccountsRequest } from '../../../settings/components/billing-subscription/billing-subscription-slice';

const mapStateToProps = (state: RootState) => ({
  emailAccounts: state.emailAccount.emailAccounts,
  subscriptionPlan: state.home.subscription?.planCode,
  planType: state.home.subscription?.planType,
  slots: state.home.subscription?.slots,
  totalEmailAccounts:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsResponse
      ?.totalEmailAccounts,
  subscriptionPlanStatus: state.home.subscription?.status,
  firstName: state.home.adminDetails?.firstName,
  sendGetReactivateSubscriptionRequestStatus:
    state.billingAndSubscription.reactivateSubscriptionRequest.status,
  customerId: state.home.subscription?.customerId,
  subscriptionEndDate: state.home.subscription?.nextBillingAt,
  connectEmailAccountRequestStatus:
    state.emailAccount.connectEmailAccountRequest.status,
  connectEmailAccountRequestError:
    state.emailAccount.connectEmailAccountRequest.error,
  getTestSMTPConnectionRequestStatus:
    state.emailAccount.testSMTPConnectionRequest.status,
  getTestIMAPConnectionRequestStatus:
    state.emailAccount.testIMAPConnectionRequest.status,
  getConnectSmtpImapRequestStatus:
    state.emailAccount.connectSmtpImapAccountRequest.status,
  sendUpdateSmtpImapAccountRequestStatus:
    state.emailAccount.updateSmtpImapAccountDetailsRequest.status,
  agencyConfig: state.home.agencyConfig,
  showAddEmailAccountModalProp: state.home.application.showAddEmailAccountModal,
  authUrl: state.emailAccount.authUrl,

  getConnectedUsersAndEmailAccountsRequestStatus:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest
      .status,
  getConnectedUsersAndEmailAccountsRequestError:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest.error,
});

const mapDispatchToProps = {
  setEmailAccountInfrastructureServiceProvider: (
    payload: EmailAccountInfrastructureServiceProvider,
  ) => setEmailAccountInfrastructureServiceProvider(payload),
  sendGetConnectedUsersAndEmailAccountsRequest: () =>
    getConnectedUsersAndEmailAccountsRequest(),
  sendReactivateSubscriptionRequest: (
    payload: ReactivateResumeSubscriptionRequest,
  ) => reactivateSubscriptionRequest(payload),
  sendConnectEmailAccountRequest: (
    method: EmailAccountMethod,
    emailAccountId?: number,
  ) => connectEmailAccountRequest({ method, emailAccountId }),
  resetConnectedUsersAndEmailAccountsRequest: () =>
    resetConnectedUsersAndEmailAccountsRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(CreateConnectEmailAccount);
