import React from 'react';
import { Bulb, Upload } from '@saleshandy/icons';

import Modal from '../../../shared/design-system/ui/modal';

interface NudgeUserToUploadProfileImageModalProps {
  show: boolean;
  onContinueAnyway: () => void;
  onUploadImage: () => void;
  onHide: () => void;
}

const NudgeUserToUploadProfileImageModal: React.FC<NudgeUserToUploadProfileImageModalProps> = ({
  show,
  onContinueAnyway,
  onUploadImage,
  onHide,
}) => (
  <Modal
    show={show}
    className="create-email-infrastructure-confirmation-modal"
    title={
      <div className="d-flex align-items-center gap-10">
        <Bulb width={24} height={24} color="#1F2937" />
        <span className="line-height-1">
          Set profile image for added email accounts
        </span>
      </div>
    }
    onHide={onHide}
    primaryBtnLabel="Upload Image"
    secondaryBtnLabel="Continue Anyway"
    primaryBtnClickedHandler={onUploadImage}
    secondaryBtnClickedHandler={onContinueAnyway}
    primaryBtnIcon={Upload}
    primaryBtnIconPlacement="left"
    secondaryBtnClassName=""
  >
    <p>
      Set profile image in added email accounts for a better deliverability.
    </p>
    <p>
      Please note that once email accounts are created, for the automation
      purposes, we restrict users from changing profile image.
    </p>
  </Modal>
);

export default NudgeUserToUploadProfileImageModal;
