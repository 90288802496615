import moment from 'moment-timezone';
import { ScoreFactorStatus } from '../score-factor-detail-item/types';
import {
  SpfScore,
  CustomDomainScore,
  DailySendingQuotaScore,
  DmarcScore,
  EmailAgeScore,
  SendingIntervalScore,
} from './enums';
import { EmailAccountSetup } from './types';

export const getLastCheckedDate = (
  emailAccountSetup: EmailAccountSetup,
  userTimezone: string,
): string => {
  if (emailAccountSetup && userTimezone) {
    const momentObj = moment(emailAccountSetup.timestamp);
    const date = momentObj.format('MMM DD');
    const year = momentObj.format('YYYY');
    const time = momentObj.format('hh:mm a').toUpperCase();
    const timezone = `GMT${momentObj.tz(userTimezone).format('Z')}`;

    return `${date}, ${year}. at ${time} ${timezone}`;
  }
  return '';
};

export const getScoreFactorStatusForSPF = (
  score: number,
): ScoreFactorStatus => {
  if (score === SpfScore.min) {
    return ScoreFactorStatus.NotValid;
  }
  return ScoreFactorStatus.Valid;
};

export const getScoreFactorStatusForCTD = (
  score: number,
): ScoreFactorStatus => {
  if (score === CustomDomainScore.min) {
    return ScoreFactorStatus.NotValid;
  }
  return ScoreFactorStatus.Valid;
};

export const getScoreFactorStatusForDSQ = (
  score: number,
): ScoreFactorStatus => {
  if (score === DailySendingQuotaScore.min) {
    return ScoreFactorStatus.NotValid;
  }
  if (score === DailySendingQuotaScore.mid) {
    return ScoreFactorStatus.PartiallyValid;
  }
  return ScoreFactorStatus.Valid;
};

export const getScoreFactorStatusForDMARC = (
  score: number,
): ScoreFactorStatus => {
  if (score === DmarcScore.min) {
    return ScoreFactorStatus.NotValid;
  }
  return ScoreFactorStatus.Valid;
};

export const getScoreFactorStatusForEmailAge = (
  score: number,
): ScoreFactorStatus => {
  if (score === EmailAgeScore.min) {
    return ScoreFactorStatus.NotValid;
  }
  if (score === EmailAgeScore.mid) {
    return ScoreFactorStatus.PartiallyValid;
  }
  return ScoreFactorStatus.Valid;
};

export const getScoreFactorStatusForSendingInterval = (
  score: number,
): ScoreFactorStatus => {
  if (score === SendingIntervalScore.min) {
    return ScoreFactorStatus.NotValid;
  }
  if (score === SendingIntervalScore.mid) {
    return ScoreFactorStatus.PartiallyValid;
  }
  return ScoreFactorStatus.Valid;
};

export const getEmailAccountSetupProgress = (
  emailAccountSetup: EmailAccountSetup,
) => {
  if (emailAccountSetup !== null) {
    if (emailAccountSetup?.total?.score <= 50) {
      return {
        scoreStatus: 'poor',
        pathColor: '#DC2626',
        trailColor: '#FEE2E2',
        textColor: '#DC2626',
      };
    }

    if (
      emailAccountSetup?.total?.score > 50 &&
      emailAccountSetup?.total?.score <= 70
    ) {
      return {
        scoreStatus: 'average',
        pathColor: '#F59E0B',
        trailColor: '#FEF3C7',
        textColor: '#F59E0B',
      };
    }

    if (
      emailAccountSetup?.total?.score > 70 &&
      emailAccountSetup?.total?.score <= 100
    ) {
      return {
        scoreStatus: 'excellent',
        pathColor: '#2DB482',
        trailColor: '#D1FAE5',
        textColor: '#2DB482',
      };
    }
  }
  return {
    scoreStatus: 'default',
    pathColor: '#ddd',
    trailColor: '#ddd',
    textColor: '#ddd',
  };
};

export const getEmailAgeDropdownSelectedKey = (score: number): string => {
  if (score === EmailAgeScore.min) {
    return '0';
  }
  if (score === EmailAgeScore.mid) {
    return '1';
  }
  return '2';
};
