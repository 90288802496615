export enum SpfScore {
  min = 0,
  max = 35,
}

export enum CustomDomainScore {
  min = 0,
  max = 25,
}

export enum DailySendingQuotaScore {
  min = 0,
  mid = 6,
  max = 12,
}

export enum DmarcScore {
  min = 0,
  max = 8,
}

export enum EmailAgeScore {
  min = 0,
  mid = 4,
  max = 8,
}

export enum SendingIntervalScore {
  min = 0,
  mid = 6,
  max = 12,
}
