import React from 'react';

interface SuggestedDomainsEmailsTableProps {
  columns: {
    key: string;
    header: string;
    component: (row: any) => JSX.Element;
  }[];
  data: any[];
  tableTitle: JSX.Element;
  actionComponent: JSX.Element;
  rowClassName?: (row: any, rowIndex: number) => string;
}

const SuggestedDomainsEmailsTable: React.FC<SuggestedDomainsEmailsTableProps> = ({
  columns = [],
  data = [],
  tableTitle = null,
  actionComponent = null,
  rowClassName = () => '',
}) => (
  <div className="suggested-domains-emails-container">
    <div className="suggested-domains-emails">
      <div className="header">
        {tableTitle}
        {actionComponent}
      </div>
      <div className="table-outer-container">
        <div className="table-container">
          <table className="domains-emails-table">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column.key}>
                    <span>{column.header}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr
                  // eslint-disable-next-line react/no-array-index-key
                  key={rowIndex}
                  className={`table-row ${rowClassName(row, rowIndex)}`}
                >
                  {columns.map((column) => (
                    <td key={column.key}>{column.component(row)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

export default SuggestedDomainsEmailsTable;
