import { Order } from '../../../shared/enums/order';
import { CustomDomainType } from '../../settings/types/custom-domain';
import { EmailAccountsSortBy, EmailAccountType } from '../enums/email-account';
import { EmailAccountSettings } from './email-account-settings';

export enum Filters {
  ClientAssociated = 'clientIds',
  EmailServiceProviders = 'emailServiceProvider',
  Tags = 'tagIds',
  Sequences = 'sequenceIds',
  EmailAccountStatus = 'status',
  EmailAccountAddedBy = 'owners',
}

export type EmailAccountsFilters = Record<Filters, any[]> & {
  page: number;
  limit: number;
  search: string;
  sortByKey: EmailAccountsSortBy;
  order: Order;
};

export type BulkSelectedEmailAccounts = {
  isAllEmailAccountsSelected?: boolean;
  selectionCount?: boolean;
  deSelectedEmailAccountIds?: any[];
};

export enum EmailAccountSortBy {
  Owner = 'owner',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

type CredentialPayload = {
  emailAddress: string;
  password: string;
  host: string;
  port: number;
};

type SmtpImapConnectionPayload = {
  id: number;
  emailAccount: number;
  smtpCredentialPayload: CredentialPayload;
  imapCredentialPayload: CredentialPayload;
  smtpConnectionErrorDetails: string | null;
  imapConnectionErrorDetails: string | null;
};

export enum EmailAccountConnectionStatus {
  Active = 'active',
  InActive = 'inActive',
  InProgress = 'inProgress',
}

export enum EmailAccountStatus {
  InActive = 0,
  Active = 1,
  InProgress = 2,
  Pause = 3,
}

export enum EmailAccountFilterStatus {
  Active = 1,
  Disconnected = 0,
  Paused = 3
}

export type EmailAccount = {
  id?: number;
  user?: any;
  fromName?: string;
  fromFirstName?: string;
  fromLastName?: string;
  fromEmail?: string;
  type?: EmailAccountType;
  status?: EmailAccountStatus;
  isDefault?: number;
  lastConnectedAt?: Date;
  createdAt?: Date | string;
  modifiedAt?: Date | string;
  settings?: EmailAccountSettings[];
  emailAccountCustomDomain?: EmailAccountCustomDomain;
  healthScore: number;
  emailServiceProvider?: string;
  smtpImapConnectionPayload?: SmtpImapConnectionPayload;
  hashId: string;
  sequences: {
    id: string;
    title: string;
  }[];
  tags: number[];
};

export type EmailAccountCustomDomain = {
  id: number;
  userCustomDomain: CustomDomainType;
};
