import React, { useEffect, useState } from 'react';
import { Button } from '@saleshandy/design-system';
import { ChevronRight, ShoppingCart } from '@saleshandy/icons';
import classNames from 'classnames';

import { CreateEmailAccountInfrastructureCart } from '../../../types/create-email-infrastructure';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../shared/design-system/components/overlay';

type CreateEmailInfrastructureFooterProps = {
  emailAccountInfrastructureCart: CreateEmailAccountInfrastructureCart;
  isNextButtonDisabled: boolean;
  isNextButtonLoading: boolean;
  onNextButtonClick: () => void;
  nextButtonText: string;
  shouldShow: boolean;
  onViewCartClick: () => void;
};

const CreateEmailInfrastructureFooter: React.FC<CreateEmailInfrastructureFooterProps> = ({
  emailAccountInfrastructureCart,
  isNextButtonDisabled,
  isNextButtonLoading,
  onNextButtonClick,
  nextButtonText,
  shouldShow,
  onViewCartClick,
}) => {
  const [showInDOM, setShowInDOM] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleViewCart = () => {
    onViewCartClick();
  };

  useEffect(() => {
    if (shouldShow) {
      setShowInDOM(true);
      // Small delay to ensure DOM update before animation
      setTimeout(() => setIsVisible(true), 50);
    } else {
      setIsVisible(false);
      // Wait for animation to complete before removing from DOM
      setTimeout(() => setShowInDOM(false), 300);
    }
  }, [shouldShow]);

  if (!showInDOM) return null;

  const footerClassName = classNames('create-email-infrastructure__footer', {
    visible: isVisible,
  });

  const renderViewCartButton = (className = '') => (
    <div
      className={`view-cart-btn ${className}`}
      {...(emailAccountInfrastructureCart?.domains.length > 0 && {
        onClick: handleViewCart,
        onMouseDown: (e: React.MouseEvent) => e.preventDefault(),
      })}
    >
      <div className="view-cart-btn--icon">
        <ShoppingCart />
      </div>
      <div className="view-cart-btn--text">
        <span>
          {emailAccountInfrastructureCart.domains.length === 1
            ? '1 Domain'
            : `${emailAccountInfrastructureCart.domains.length} Domains`}
        </span>
        {emailAccountInfrastructureCart.emailAccounts.length > 0 && (
          <span>
            {emailAccountInfrastructureCart.emailAccounts.length === 1
              ? '1 Email Account'
              : `${emailAccountInfrastructureCart.emailAccounts.length} Email Accounts`}
          </span>
        )}
      </div>
    </div>
  );

  return (
    <div className={footerClassName}>
      <div className="create-email-infrastructure__footer--container">
        <div className="create-email-infrastructure__footer--left">
          {emailAccountInfrastructureCart.domains.length > 0 ? (
            <OverlayTooltip text="View Cart" placement={Placement.Top}>
              {renderViewCartButton()}
            </OverlayTooltip>
          ) : (
            renderViewCartButton('noDomains')
          )}

          {emailAccountInfrastructureCart.domains.length > 0 && (
            <div className="create-email-infrastructure__footer--left--divider" />
          )}

          <div className="create-email-infrastructure__footer--left--total">
            {emailAccountInfrastructureCart.domains.length > 0 && (
              <span>
                {`$${emailAccountInfrastructureCart.domains.reduce(
                  (total, domain) => total + Number(domain.price),
                  0,
                )}/year`}
              </span>
            )}
            {emailAccountInfrastructureCart.emailAccounts.length > 0 && (
              <span>
                {`$${
                  emailAccountInfrastructureCart.emailAccounts.reduce(
                    (total, emailAccount) => total + Number(emailAccount.price),
                    0,
                  ) * 3
                }/quarter`}
              </span>
            )}
          </div>
        </div>
        <div className="create-email-infrastructure__footer--right">
          <Button
            disabled={isNextButtonDisabled}
            isLoading={isNextButtonLoading}
            onClick={onNextButtonClick}
            loadingText={nextButtonText}
            icon={ChevronRight}
            iconPlacement="right"
          >
            {nextButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateEmailInfrastructureFooter;
