import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store/root-reducer';

// eslint-disable-next-line import/no-cycle
import EmailAccountSetupScore from './email-account-setup-score';
import { AgencyConfig } from '../../../components/home/types';
import { EmailAccountSetup } from './types';
import { updateEmailAgeRequest } from '../../../components/email-accounts/extra-actions';
import { resetUpdateEmailAgeRequest } from '../../../components/email-accounts/email-accounts-slice';
import { EmailAgePayload } from '../../../components/email-accounts/types/request-payload';

const mapStateToProps = (state: RootState) => ({
  updateEmailAgeRequestStatus: state.emailAccount.updateEmailAgeRequest.status,
  updateEmailAgeRequestMessage:
    state.emailAccount.updateEmailAgeRequest.message,
  updateEmailAgeRequestError: state.emailAccount.updateEmailAgeRequest.error,
});

const mapDispatchToProps = {
  sendUpdateEmailAgeRequest: (payload: EmailAgePayload) =>
    updateEmailAgeRequest(payload),
  resetUpdateEmailAgeRequest: () => resetUpdateEmailAgeRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  emailAccountSetup: EmailAccountSetup;
  onRefreshEmailAccountSetupScore: VoidFunction;
  userTimezone: string;
  agencyConfig: Partial<AgencyConfig>;
  showNeedHelpCTA?: boolean;
  showRefreshScoreBtn?: boolean;
  showLastUpdatedDate?: boolean;
  showDescription?: boolean;
  showActionableList?: boolean;
  showProgress?: boolean;
  showMetaData?: boolean;
  className?: string;
  getItDoneByExpertsButtonVisible?: boolean;
};

export default connector(EmailAccountSetupScore);
