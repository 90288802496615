/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import { Button } from '@saleshandy/design-system';
import { Bulb, InfoCircle } from '@saleshandy/icons';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import type { IProps } from './create-connect-email-account-container';

import { SMTPIMAP } from '../../enums/smtp-or-imap';
import { EmailAccountMethod } from '../../enums/email-account';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import { EmailAccountInfrastructureServiceProvider } from '../../enums/create-email-infrastructure';
import SubscriptionStatus from '../../../settings/components/billing-subscription/enums/subscription-status';

import {
  OverlayTooltip,
  Placement,
} from '../../../../shared/design-system/components/overlay';

import ErrorLogModal from '../email-accounts-content/components/error-log-modal';
import RestrictionErrorModal from '../../../../shared/components/restriction-error-modal';
import CreateNewSendingInfrastructureModal from '../../modals/add-sending-infrastructure-modal';
import AddEmailAccountModal from '../email-accounts-content/components/add-email-account-modal';
import AddBulkImportModal from '../email-accounts-content/components/add-email-bulk-import-modal';
import ConnectSMTPIMAPAccountModal from '../email-accounts-content/components/connect-smtp-imap-account-modal';
import NoSeatAvailableForEmailModal from '../email-accounts-content/components/no-seat-available-for-email-modal';
import ReactivateSubscriptionModal from '../../../settings/components/billing-subscription/components/reactivate-subscription-modal';

import hasResource from '../../../../shared/utils/access-control/has-resource';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import planPermissionError from '../../../../shared/utils/errors/plan-permission-error';
import { ResourceIdentifiers } from '../../../../shared/utils/access-control/enums/resource-identifiers';
import { isValueBasedPlan } from '../../../settings/components/billing-subscription/components/outreach-plans/utils/helper';
import { getEmailAccountId } from '../email-accounts-content/components/update-email-account/helpers';
import { Images } from '../../../../shared/app-constants';
import { isFreePlan } from '../../../../shared/utils/plans';
import {
  contactAdmin,
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
  redirectWithRefresh,
  SubscriptionPlans,
  SubscriptionPlanTitle,
} from '../../../../shared/utils';
import toaster from '../../../../shared/toaster';
import { showToasterWithErrorCheck } from '../email-accounts-content/utils/helpers';

const CheckIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2003 5.60156L6.42728 10.4016L4.80029 8.76537"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const FeatureIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.11755 1.60156L4.89738 4.89903L1.5999 6.11921L4.89738 7.33938L6.11755 10.6369L7.33772 7.33938L10.6352 6.11921L7.33772 4.89903L6.11755 1.60156Z"
      fill="#1D4ED8"
    />
    <path
      d="M11.7646 9.13097L10.6987 10.7004L9.12931 11.7663L10.6987 12.8322L11.7646 14.4016L12.8305 12.8322L14.3999 11.7663L12.8305 10.7004L11.7646 9.13097Z"
      fill="#1D4ED8"
    />
    <path
      d="M6.11755 1.60156L4.89738 4.89903L1.5999 6.11921L4.89738 7.33938L6.11755 10.6369L7.33772 7.33938L10.6352 6.11921L7.33772 4.89903L6.11755 1.60156Z"
      stroke="#1D4ED8"
      strokeWidth="0.666667"
      strokeLinejoin="round"
    />
    <path
      d="M11.7646 9.13097L10.6987 10.7004L9.12931 11.7663L10.6987 12.8322L11.7646 14.4016L12.8305 12.8322L14.3999 11.7663L12.8305 10.7004L11.7646 9.13097Z"
      stroke="#1D4ED8"
      strokeWidth="0.666667"
      strokeLinejoin="round"
    />
  </svg>
);

const VideoIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.727273"
      y="0.727273"
      width="14.4093"
      height="14.5455"
      rx="7.20467"
      fill="white"
      stroke="#275DF5"
      strokeWidth="1.45455"
    />
    <path
      d="M7.10635 5.26585L10.8684 7.58966C10.9819 7.71947 11.0378 7.86939 11.0378 8.00286C11.0378 8.13633 10.9815 8.30234 10.8688 8.41661L7.1068 10.7404C6.94175 10.9078 6.6938 10.958 6.47802 10.8674C6.26223 10.7767 6.12207 10.5954 6.12207 10.3431V5.67905C6.12207 5.44246 6.26234 5.22891 6.47811 5.13823C6.69387 5.04754 6.9423 5.09819 7.10635 5.26585Z"
      fill="#275DF5"
    />
  </svg>
);

const CreateConnectEmailAccount: React.FC<IProps> = ({
  setEmailAccountInfrastructureServiceProvider,
  sendGetConnectedUsersAndEmailAccountsRequest,
  getConnectedUsersAndEmailAccountsRequestStatus,
  getConnectedUsersAndEmailAccountsRequestError,
  resetConnectedUsersAndEmailAccountsRequest,
  subscriptionPlan,
  planType,
  slots,
  subscriptionPlanStatus,
  totalEmailAccounts,
  firstName,
  sendGetReactivateSubscriptionRequestStatus,
  sendReactivateSubscriptionRequest,
  customerId,
  subscriptionEndDate,
  connectEmailAccountRequestStatus,
  getTestSMTPConnectionRequestStatus,
  getTestIMAPConnectionRequestStatus,
  getConnectSmtpImapRequestStatus,
  sendUpdateSmtpImapAccountRequestStatus,
  agencyConfig,
  sendConnectEmailAccountRequest,
  authUrl,
  connectEmailAccountRequestError,
}) => {
  const [
    showAddNewEmailInfrastructureModal,
    setShowAddNewEmailInfrastructureModal,
  ] = useState(false);
  const [
    showReactivateSubscriptionModal,
    setShowReactivateSubscriptionModal,
  ] = useState(false);
  const [restrictionErrorModalMeta, setRestrictionErrorModalMeta] = useState({
    show: false,
    planName: '',
  });
  const [
    showNoSeatAvailableForEmailModal,
    setShowNoSeatAvailableForEmailModal,
  ] = useState(false);
  const [
    showConnectAndSaveSmtpImapAccountModel,
    setShowConnectAndSaveSmtpImapAccountModel,
  ] = useState(false);

  const [showBulkModal, setShowBulkModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [isErrorLogModalVisible, setIsErrorLogModalVisible] = useState(false);
  const [errorLogAccountType, setErrorLogAccountType] = useState(SMTPIMAP.SMTP);
  const [errorLog, setErrorLog] = useState('');

  const history = useHistory();
  const { t } = useTranslation();

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const hashId = params.get('hashId');
  const emailAccountId = hashId && getEmailAccountId(hashId);

  const featuresGroup1 = [
    'We set up your accounts',
    'Automatic reconnects',
    'US IPs only',
  ];

  const featuresGroup2 = [
    'Email deliverability optimized',
    'SPF, DMARC & DKIM set accounts',
    'Starting at $4/mo',
  ];

  const FeatureItem = ({ text }) => (
    <li className="feature-item">
      <CheckIcon />
      <span className="feature-text">{text}</span>
    </li>
  );

  const FeaturesList = ({ features }) => (
    <div className="features-group">
      {features.map((feature, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <FeatureItem key={index} text={feature} />
      ))}
    </div>
  );

  const openAddNewEmailInfrastructureModal = () => {
    setShowAddNewEmailInfrastructureModal(true);
  };

  const closeAddNewEmailInfrastructureModal = () => {
    setShowAddNewEmailInfrastructureModal(false);
  };

  const handleOnSubmit = (
    payload: EmailAccountInfrastructureServiceProvider,
  ) => {
    setEmailAccountInfrastructureServiceProvider(payload);
    history.push('/email-accounts/create-email-accounts');
  };

  function showAddEmailAccountModal() {
    if (
      subscriptionPlan !== SubscriptionPlans.FREE &&
      subscriptionPlan !== SubscriptionPlans.TRIAL &&
      Number(totalEmailAccounts) >= slots &&
      !isValueBasedPlan(planType)
    ) {
      if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
        if (subscriptionPlanStatus === SubscriptionStatus.Canceled) {
          setShowReactivateSubscriptionModal(true);
          return;
        }
        if (subscriptionPlan === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME) {
          setRestrictionErrorModalMeta({
            show: true,
            planName: SubscriptionPlanTitle.EmailOutreach,
          });
          return;
        }
        if (
          subscriptionPlan === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME
        ) {
          setRestrictionErrorModalMeta({
            show: true,
            planName: SubscriptionPlanTitle.EmailOutreachPro,
          });
          return;
        }
      }
      setShowNoSeatAvailableForEmailModal(true);
    } else {
      setEmailModal(true);
    }
  }

  function showAddEmailAccountModalHandler() {
    if (hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT)) {
      if (subscriptionPlan === SubscriptionPlans.TRIAL) {
        showAddEmailAccountModal();
      } else {
        hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT) &&
          sendGetConnectedUsersAndEmailAccountsRequest();
      }
    }
  }
  const hideRestrictionErrorModal = () => {
    setRestrictionErrorModalMeta({
      planName: '',
      show: false,
    });
  };

  const hideReactivateSubscriptionModal = () => {
    setShowReactivateSubscriptionModal(false);
  };

  const hideNoSeatAvailableForEmailModal = () => {
    setShowNoSeatAvailableForEmailModal(false);
  };

  const hideAddEmailAccountModal = () => {
    setEmailModal(false);
  };

  const hideConnectAndSaveSmtpImapAccountModel = () => {
    setShowConnectAndSaveSmtpImapAccountModel(false);
  };

  const hideAddBulkImportModal = () => {
    setShowBulkModal(false);
  };

  const hideErrorLogModal = () => {
    setIsErrorLogModalVisible(false);
  };

  function reactivateSubscription() {
    const emailBody = `Hello ${firstName}, %0D%0A %0D%0A ${t(
      'messages.reactivate_subscription_email_body',
    )}`;
    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      sendReactivateSubscriptionRequest({ customerId });
    } else {
      contactAdmin(t('messages.reactivate_subscription'), emailBody);
    }
  }

  const handlerToShowConnectAndSaveSmtpImapAccountModel = () => {
    if (
      !hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT) &&
      isFreePlan()
    ) {
      const title = 'Please upgrade to enable the sequence';
      const content = [
        'Your account is downgraded to a free plan. Upgrade to a paid plan to enable the sequence and resume email sending.',
      ];

      planPermissionError.showModal(title, content, true);
      return;
    }

    setShowConnectAndSaveSmtpImapAccountModel(true);
    setEmailModal(false);
  };

  const handlerToOpenCSVAccountModal = () => {
    setShowBulkModal(true);
    setEmailModal(false);
  };

  function addEmailAccountHandler(method: EmailAccountMethod) {
    hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT) &&
      sendConnectEmailAccountRequest(method, emailAccountId);
  }

  function showErrorLogModal(accountType: SMTPIMAP, errorLogVal: string) {
    setIsErrorLogModalVisible(true);

    setErrorLogAccountType(accountType);

    setErrorLog(errorLogVal);
  }

  useEffect(() => {
    executeOnRequestStatus({
      status: getConnectedUsersAndEmailAccountsRequestStatus,
      onSuccess: () => {
        showAddEmailAccountModal();
        resetConnectedUsersAndEmailAccountsRequest();
      },
      onFailed: () => {
        resetConnectedUsersAndEmailAccountsRequest();
        executeOnErrorWithErrorCheck({
          error: getConnectedUsersAndEmailAccountsRequestError,
          onError: () => {
            toaster.error(
              getConnectedUsersAndEmailAccountsRequestError.message,
            );
          },
        });
      },
    });
  }, [getConnectedUsersAndEmailAccountsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: connectEmailAccountRequestStatus,
      onSuccess: () => {
        redirectWithRefresh(authUrl);
      },
      onFailed: () => {
        showToasterWithErrorCheck(connectEmailAccountRequestError);
      },
    });
  }, [connectEmailAccountRequestStatus]);

  const isConnectEmailAccountRequestPending =
    connectEmailAccountRequestStatus === RequestStatus.Pending;

  return (
    <div className="main-container">
      <div className="create-connect-email-account-container">
        <div className="email-section-one">
          <img
            src={Images.EmailConfiguration}
            alt="Connect existing email accounts"
            width={72}
            height={72}
          />
          <p className="create-connect-email-account-title">
            Connect existing email accounts
          </p>
          <p className="create-connect-email-account-description">
            Connect Existing Google Workspace, Microsoft, Sendgrid or others via
            SMTP
          </p>
          <a href="#" className="create-connect-email-account-learn-more">
            Learn More
            <VideoIcon />
          </a>
          <Button
            className="email-section-one-btn"
            onClick={showAddEmailAccountModalHandler}
            disabled={!hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT)}
            isLoading={getIsRequestPending(
              getConnectedUsersAndEmailAccountsRequestStatus,
            )}
            loadingText="Connecting"
          >
            Connect Email Accounts
          </Button>
        </div>

        <div className="vertical-divider" />

        <div className="email-section-two">
          <img
            src={Images.CreateNewEmail}
            alt="Create new Sending infrastructure"
            width={72}
            height={72}
          />
          <p className="create-connect-email-account-title">
            Create new Sending infrastructure
            <OverlayTooltip
              placement={Placement.Bottom}
              text="Create hundreds of domains & email accounts with improved deliverability and automated setup."
            >
              <InfoCircle width={16} height={16} />
            </OverlayTooltip>
          </p>
          <p className="create-connect-email-account-description">
            Quickly set up sending infra using alternate lookalike domains!
          </p>
          <a href="#" className="create-connect-email-account-learn-more">
            Learn More
            <VideoIcon />
          </a>
          <Button
            className="email-section-two-btn"
            onClick={openAddNewEmailInfrastructureModal}
          >
            Create New Email Infra
          </Button>
          <p className="features-title">
            <FeatureIcon /> Why its best suitable for you?
          </p>
          <ul className="features-list">
            <FeaturesList features={featuresGroup1} />
            <FeaturesList features={featuresGroup2} />
          </ul>
        </div>
      </div>

      <div className="recommendation-container">
        <Bulb width={16} height={16} className="recommendation-icon" />

        <div className="recommendation-content">
          <p className="recommendation-header">Recommendation:</p>
          <p className="recommendation-description">
            Cold emailing may harm your primary domain’s reputation if messages
            are marked as spam. Protect it by using a secondary lookalike domain
            for outreach, ensuring your main domain stays intact. If your
            primary domain is ‘example.com’, We recommend using ‘getexample.com’
            or ‘examplehq.com’ for your outreach.
          </p>
        </div>
      </div>

      {showAddNewEmailInfrastructureModal && (
        <CreateNewSendingInfrastructureModal
          show={showAddNewEmailInfrastructureModal}
          onSubmit={handleOnSubmit}
          onClose={closeAddNewEmailInfrastructureModal}
        />
      )}

      <AddEmailAccountModal
        show={emailModal}
        onSubmit={addEmailAccountHandler}
        onClose={hideAddEmailAccountModal}
        isRequestPending={isConnectEmailAccountRequestPending}
        t={t}
        onCSVSelect={handlerToOpenCSVAccountModal}
        onSMTPAccountSelect={handlerToShowConnectAndSaveSmtpImapAccountModel}
      />

      <ReactivateSubscriptionModal
        show={showReactivateSubscriptionModal}
        onClose={hideReactivateSubscriptionModal}
        onReactivateSubscription={reactivateSubscription}
        subscriptionEndDate={subscriptionEndDate}
        isRequestPending={
          sendGetReactivateSubscriptionRequestStatus === RequestStatus.Pending
        }
        reactivateSubscriptionModalMessage1="not_allowed_to_add_email_accounts_message_1"
        reactivateSubscriptionModalMessage2="not_allowed_to_add_email_accounts_message_2"
        reactivateSubscriptionModalHeaderMessage="no_seat_available_email_modal_title"
        submitButtonText={
          hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
            ? 'reactivate_subscription'
            : 'contact_admin'
        }
      />

      {restrictionErrorModalMeta.show && (
        <RestrictionErrorModal
          show={restrictionErrorModalMeta.show}
          onClose={hideRestrictionErrorModal}
          planName={restrictionErrorModalMeta.planName}
          restrictionOn="email slots"
          feature="email accounts"
          firstName={firstName}
        />
      )}

      <NoSeatAvailableForEmailModal
        show={showNoSeatAvailableForEmailModal}
        onClose={hideNoSeatAvailableForEmailModal}
        firstName={firstName}
      />

      <ConnectSMTPIMAPAccountModal
        show={showConnectAndSaveSmtpImapAccountModel}
        onClose={hideConnectAndSaveSmtpImapAccountModel}
        isSMTPTestConnectionRequestPending={
          getTestSMTPConnectionRequestStatus === RequestStatus.Pending
        }
        isIMAPTestConnectionRequestPending={
          getTestIMAPConnectionRequestStatus === RequestStatus.Pending
        }
        isConnectAccountRequestPending={
          getConnectSmtpImapRequestStatus === RequestStatus.Pending
        }
        isUpdateSmtpImapAccountRequestPending={
          sendUpdateSmtpImapAccountRequestStatus === RequestStatus.Pending
        }
        emailAccountId={emailAccountId}
        agencyConfig={agencyConfig}
        showErrorLogModal={(accountType, eLog) =>
          showErrorLogModal(accountType, eLog)
        }
      />

      <AddBulkImportModal
        show={showBulkModal}
        onCancel={hideAddBulkImportModal}
      />

      <ErrorLogModal
        show={isErrorLogModalVisible}
        onClose={() => hideErrorLogModal()}
        accountType={errorLogAccountType}
        errorLog={errorLog}
      />
    </div>
  );
};

export default CreateConnectEmailAccount;
