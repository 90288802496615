import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Tabs, { TabPane } from 'rc-tabs';
import type { IProps } from './create-email-infrastructure-container';
import { EmailInfrastructureStep } from '../../enums/create-email-infrastructure';
import CreateEmailInfrastructureTabs from './components/create-email-infrastructure-tabs';
import PurchaseDomainsTab from './components/purchase-domains-tab';
import CreateEmailAccountsTab from './components/create-email-accounts-tab';
import ContactDetailsTab from './components/contact-details-tab';
import CreateEmailInfrastructureFooter from './components/create-email-infrastructure-footer';
import { getIsRequestPending } from '../../../../shared/utils';
import { generateDefaultEmailAccountsForDomains } from './utils/generate-default-email-accounts-for-domains';
import CreateEmailInfrastructureCart from '../../modals/create-email-infrastructure-cart';
import ClearAllCartModal from '../../modals/clear-all-cart-modal';
import toaster from '../../../../shared/toaster';
import {
  getFromLocalStorage,
  LOCAL_STORAGE_KEYS,
} from '../../helpers/email-accounts-infra-local-storage';

const CreateEmailInfrastructure: React.FC<IProps> = ({
  emailAccountInfrastructureServiceProvider,
  emailAccountInfrastructureCart,
  emailAccountInfrastructureForwardingDomain,

  domains,
  domainPlans,
  sendGetDomainPlansRequest,
  getDomainSearchRequestStatus,

  firstName,
  lastName,
  removeDomainFromEmailAccountInfrastructureCart,
  addEmailAccountToEmailAccountInfrastructureCart,
  removeEmailAccountFromEmailAccountInfrastructureCart,
  resetEmailAccountInfrastructureCart,
  hasErrorsInContactDetailsForm,
}) => {
  const history = useHistory();
  const location = useLocation();

  const [activeKey, setActiveKey] = useState(
    EmailInfrastructureStep.PurchaseDomains,
  );

  const [completedTabs, setCompletedTabs] = useState<Set<string>>(new Set());
  const [
    showSetForwardingDomainModal,
    setShowSetForwardingDomainModal,
  ] = useState(false);

  const [
    nudgeUserToUploadProfileImageModal,
    setNudgeUserToUploadProfileImageModal,
  ] = useState(false);

  const [showBillingSummaryModal, setShowBillingSummaryModal] = useState(false);

  const [
    showCreateEmailInfrastructureCart,
    setShowCreateEmailInfrastructureCart,
  ] = useState(false);
  const [showClearAllCartModal, setShowClearAllCartModal] = useState(false);

  const onShowCreateEmailInfrastructureCart = () => {
    setShowCreateEmailInfrastructureCart(true);
  };

  const onCloseCreateEmailInfrastructureCart = () => {
    setShowCreateEmailInfrastructureCart(false);
  };

  const onShowClearAllCartModal = () => {
    setShowClearAllCartModal(true);
  };

  const onCloseClearAllCartModal = () => {
    setShowClearAllCartModal(false);
  };

  // tabs to be displayed in the create email infrastructure page
  const tabs = useMemo(
    () => [
      {
        key: EmailInfrastructureStep.PurchaseDomains,
        label: 'Purchase Domains',
        isCompleted: completedTabs.has(EmailInfrastructureStep.PurchaseDomains),
      },
      {
        key: EmailInfrastructureStep.CreateEmailAccounts,
        label: 'Create Email Accounts',
        isCompleted: completedTabs.has(
          EmailInfrastructureStep.CreateEmailAccounts,
        ),
      },
      {
        key: EmailInfrastructureStep.ContactDetails,
        label: 'Contact Details',
        isCompleted: completedTabs.has(EmailInfrastructureStep.ContactDetails),
      },
    ],
    [completedTabs],
  );

  const nextButtonText = useMemo(() => {
    if (activeKey === EmailInfrastructureStep.ContactDetails) {
      return 'Continue to Payment';
    }
    return 'Next';
  }, [activeKey]);

  const isNextButtonLoading = useMemo(() => false, []);

  const isNextButtonDisabled = useMemo(() => {
    if (activeKey === EmailInfrastructureStep.PurchaseDomains) {
      return emailAccountInfrastructureCart?.domains?.length === 0;
    }

    if (activeKey === EmailInfrastructureStep.CreateEmailAccounts) {
      const emailInfradomains = emailAccountInfrastructureCart?.domains || [];
      const emailAccounts = emailAccountInfrastructureCart?.emailAccounts || [];

      const eachDomainHasEmailAccount = emailInfradomains.every((domain) =>
        emailAccounts.some((email) => email.email.endsWith(`@${domain.name}`)),
      );

      return (
        emailAccounts.length === 0 ||
        emailAccounts.some((email) => !email.profile?.url) ||
        !eachDomainHasEmailAccount
      );
    }

    if (activeKey === EmailInfrastructureStep.ContactDetails) {
      return hasErrorsInContactDetailsForm;
    }

    return true;
  }, [
    activeKey,
    emailAccountInfrastructureCart,
    hasErrorsInContactDetailsForm,
  ]);

  const handleTabChange = (key: string) => {
    setActiveKey(key as EmailInfrastructureStep);
    history.push(`?step=${key}`);
  };

  const handleNextButtonClickOnPurchaseDomains = () => {
    const storedCart = getFromLocalStorage(
      LOCAL_STORAGE_KEYS.EMAIL_ACCOUNT_INFRASTRUCTURE_CART,
    );

    if (!storedCart || storedCart.emailAccounts.length === 0) {
      generateDefaultEmailAccountsForDomains(
        firstName,
        lastName,
        emailAccountInfrastructureCart,
        domainPlans,
        addEmailAccountToEmailAccountInfrastructureCart,
      );
    }
    setCompletedTabs((prev) => new Set(Array.from(prev).concat(activeKey)));
    handleTabChange(EmailInfrastructureStep.CreateEmailAccounts);
  };

  const handleNextButtonClickOnCreateEmailAccounts = () => {
    setCompletedTabs((prev) => new Set(Array.from(prev).concat(activeKey)));
    handleTabChange(EmailInfrastructureStep.ContactDetails);

    if (nudgeUserToUploadProfileImageModal) {
      setNudgeUserToUploadProfileImageModal(false);
    }
  };

  // handle the next button click and mark the tab as completed
  const handleNextButtonClick = () => {
    if (activeKey === EmailInfrastructureStep.PurchaseDomains) {
      if (emailAccountInfrastructureForwardingDomain) {
        handleNextButtonClickOnPurchaseDomains();
        return;
      }

      setShowSetForwardingDomainModal(true);
      return;
    }

    if (activeKey === EmailInfrastructureStep.CreateEmailAccounts) {
      const allEmailsHaveProfileURL = emailAccountInfrastructureCart.emailAccounts.every(
        (email) => email.profile,
      );

      if (!allEmailsHaveProfileURL) {
        setNudgeUserToUploadProfileImageModal(true);
        return;
      }

      handleNextButtonClickOnCreateEmailAccounts();
      return;
    }

    if (activeKey === EmailInfrastructureStep.ContactDetails) {
      setShowBillingSummaryModal(true);
    }
  };

  const handleClearAllClick = () => {
    resetEmailAccountInfrastructureCart();
    onCloseClearAllCartModal();
    onCloseCreateEmailInfrastructureCart();
    handleTabChange(EmailInfrastructureStep.PurchaseDomains);
    toaster.success(
      `Domains ${
        emailAccountInfrastructureCart.emailAccounts.length > 0
          ? 'and Email Accounts'
          : ''
      } removed successfully from your cart`,
    );
  };

  // set the active tab from the url query param
  useEffect(() => {
    const tabFromUrl = new URLSearchParams(location.search).get('step');
    if (tabFromUrl && tabs.some((tab) => tab.key === tabFromUrl)) {
      setActiveKey(tabFromUrl as EmailInfrastructureStep);
    } else {
      history.push(`?step=${activeKey}`, { replace: true });
    }
  }, [location.search]);

  // add chevron to the tabs if there are more than one tab
  useEffect(() => {
    const bsTabsTab = document.querySelectorAll('.bs-tabs-tab');

    if (bsTabsTab?.length > 0) {
      bsTabsTab.forEach((tab, index) => {
        if (index < bsTabsTab.length - 1) {
          const chevronDiv = document.createElement('div');
          chevronDiv.className =
            'create-email-infrastructure__tabs--item--chevron';
          chevronDiv.innerHTML = `<svg width="16" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.5 12L11 8L6.5 4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`;
          tab.after(chevronDiv);
        }
      });
    }
  }, []);

  useEffect(() => {
    sendGetDomainPlansRequest();
  }, []);

  // if the cart is empty, go to the purchase domains tab
  // and remove the purchase domains tab from the completed tabs
  useEffect(() => {
    if (emailAccountInfrastructureCart?.domains?.length === 0) {
      handleTabChange(EmailInfrastructureStep.PurchaseDomains);
      setCompletedTabs(new Set());
      if (showCreateEmailInfrastructureCart) {
        setShowCreateEmailInfrastructureCart(false);
      }
    }
  }, [emailAccountInfrastructureCart?.domains?.length]);

  useEffect(() => {
    if (emailAccountInfrastructureCart?.emailAccounts?.length === 0) {
      if (completedTabs.has(EmailInfrastructureStep.CreateEmailAccounts)) {
        setCompletedTabs(
          (prev) =>
            new Set(
              Array.from(prev).filter(
                (tab) => tab !== EmailInfrastructureStep.CreateEmailAccounts,
              ),
            ),
        );
      }
    }
  }, [emailAccountInfrastructureCart?.emailAccounts?.length]);

  useEffect(() => {
    if (emailAccountInfrastructureCart?.domains?.length > 0) {
      setCompletedTabs((prev) => {
        const updatedSet = new Set(prev);
        updatedSet.add(EmailInfrastructureStep.PurchaseDomains);
        return updatedSet;
      });
    }

    if (
      emailAccountInfrastructureCart?.emailAccounts?.length > 0 &&
      emailAccountInfrastructureCart.emailAccounts.every(
        (email) => email.profile?.url,
      )
    ) {
      setCompletedTabs((prev) => {
        const updatedSet = new Set(prev);
        updatedSet.add(EmailInfrastructureStep.CreateEmailAccounts);
        return updatedSet;
      });
    }

    if (!hasErrorsInContactDetailsForm) {
      setCompletedTabs((prev) => {
        const updatedSet = new Set(prev);
        updatedSet.add(EmailInfrastructureStep.ContactDetails);
        return updatedSet;
      });
    }
  }, [
    emailAccountInfrastructureCart?.domains?.length,
    emailAccountInfrastructureCart?.emailAccounts,
    hasErrorsInContactDetailsForm,
  ]);

  // if the email account infrastructure service provider is not found,
  // redirect to the email accounts page
  if (!emailAccountInfrastructureServiceProvider) {
    history.push('/email-accounts/create', { replace: true });
    return null;
  }

  return (
    <div className="create-email-infrastructure">
      <Tabs
        activeKey={activeKey}
        onChange={handleTabChange}
        prefixCls="bs-tabs"
        className="bs-tabs-small create-email-infrastructure__tabs"
      >
        {tabs.map((tab) => (
          <TabPane
            key={tab.key}
            tab={
              <CreateEmailInfrastructureTabs tab={tab} activeKey={activeKey} />
            }
            disabled={!tab.isCompleted && tab.key !== activeKey}
          >
            {tab.key === EmailInfrastructureStep.PurchaseDomains && (
              <PurchaseDomainsTab
                showSetForwardingDomainModal={showSetForwardingDomainModal}
                setShowSetForwardingDomainModal={
                  setShowSetForwardingDomainModal
                }
                handleNextButtonClickOnPurchaseDomains={
                  handleNextButtonClickOnPurchaseDomains
                }
              />
            )}
            {tab.key === EmailInfrastructureStep.CreateEmailAccounts && (
              <CreateEmailAccountsTab
                nudgeUserToUploadProfileImageModal={
                  nudgeUserToUploadProfileImageModal
                }
                setNudgeUserToUploadProfileImageModal={
                  setNudgeUserToUploadProfileImageModal
                }
                handleNextButtonClickOnCreateEmailAccounts={
                  handleNextButtonClickOnCreateEmailAccounts
                }
              />
            )}
            {tab.key === EmailInfrastructureStep.ContactDetails && (
              <ContactDetailsTab
                showBillingSummaryModal={showBillingSummaryModal}
                setShowBillingSummaryModal={setShowBillingSummaryModal}
              />
            )}
          </TabPane>
        ))}
      </Tabs>

      <CreateEmailInfrastructureFooter
        emailAccountInfrastructureCart={emailAccountInfrastructureCart}
        nextButtonText={nextButtonText}
        isNextButtonLoading={isNextButtonLoading}
        isNextButtonDisabled={isNextButtonDisabled}
        onNextButtonClick={handleNextButtonClick}
        shouldShow={
          getIsRequestPending(getDomainSearchRequestStatus) ||
          domains?.length > 0 ||
          emailAccountInfrastructureCart?.domains?.length > 0
        }
        onViewCartClick={onShowCreateEmailInfrastructureCart}
      />

      <CreateEmailInfrastructureCart
        show={showCreateEmailInfrastructureCart}
        onClose={onCloseCreateEmailInfrastructureCart}
        emailAccountInfrastructureCart={emailAccountInfrastructureCart}
        nextButtonText={nextButtonText}
        isNextButtonLoading={isNextButtonLoading}
        isNextButtonDisabled={isNextButtonDisabled}
        onNextButtonClick={handleNextButtonClick}
        onClearAllClick={onShowClearAllCartModal}
        removeDomainFromCart={removeDomainFromEmailAccountInfrastructureCart}
        removeEmailAccountFromCart={
          removeEmailAccountFromEmailAccountInfrastructureCart
        }
      />

      <ClearAllCartModal
        show={showClearAllCartModal}
        onSubmit={handleClearAllClick}
        onClose={onCloseClearAllCartModal}
        emailAccountInfrastructureCart={emailAccountInfrastructureCart}
      />
    </div>
  );
};

export default CreateEmailInfrastructure;
