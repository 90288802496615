import React from 'react';

import Switch, { Size } from '../../switch';

type MultichannelBetaAccessSwitchProps = {
  handleShowMultichannelBetaAccessConfirmationModal: () => void;
};

const MultichannelBetaAccessSwitch: React.FC<MultichannelBetaAccessSwitchProps> = ({
  handleShowMultichannelBetaAccessConfirmationModal,
}) => (
  <div className="multichannel-beta-access-container">
    <div className="multichannel-beta-access-switch">
      <Switch
        checked={false}
        onChange={handleShowMultichannelBetaAccessConfirmationModal}
        size={Size.Small}
      />
      <div className="multichannel-beta-access-switch-label">
        <span>Multi-channel Access</span>
        <span className="beta-tag">Beta</span>
      </div>
    </div>
    <p className="multichannel-beta-access-switch-description">
      Smarter sequence to engage prospects via LinkedIn, Calls, and Manual tasks
      along with emails to maximize outreach results.
    </p>
  </div>
);

export default MultichannelBetaAccessSwitch;
