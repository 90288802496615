import React from 'react';
import ImageIcon from '../../../../../shared/components/images/image-icon';
import { Images } from '../../../../../shared/app-constants';

export const SuggestedDomainsEmptyList = () => (
  <div className="suggested-domains-empty-list">
    <div className="empty-list">
      <ImageIcon src={Images.EmptyListSearchDomain} />
      <div className="empty-list-header">
        Search, explore and get secondary domains
      </div>
      <div className="empty-list-note">
        List of secondary domains will appear here once you enter your primary
        domain in the search box
      </div>
    </div>
  </div>
);
