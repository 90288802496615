import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ScoreFactorDetailItem from '../score-factor-detail-item';
import YoutubeVideoModal from '../../design-system/components/molecules/youtube-video-modal';

import { supportUrls } from '../../utils/urls';
import { EmailAccountSetupFactorsProps } from './types';
import {
  SpfScore,
  CustomDomainScore,
  DailySendingQuotaScore,
  DmarcScore,
  EmailAgeScore,
  SendingIntervalScore,
} from './enums';
import {
  getEmailAgeDropdownSelectedKey,
  getScoreFactorStatusForCTD,
  getScoreFactorStatusForDMARC,
  getScoreFactorStatusForDSQ,
  getScoreFactorStatusForEmailAge,
  getScoreFactorStatusForSPF,
  getScoreFactorStatusForSendingInterval,
} from './helper';
import {
  getCTDDescription,
  getDMARCDescription,
  getDSQDescription,
  getEmailAgeDescription,
  getSPFDescription,
  getSendingIntervalDescription,
} from './get-descriptions';
import { RouteParams } from '../../../components/email-accounts/components/email-accounts-content/components/update-email-account/types';

const EmailAccountSetupFactors: React.FC<EmailAccountSetupFactorsProps> = ({
  emailAccountSetup,
  agencyConfig,
  showActionableList,
  sendUpdateEmailAgeRequest,
}) => {
  const history = useHistory();
  const params = useParams<RouteParams>();

  const [showYoutubeVideoModal, setShowYoutubeVideoModal] = useState(false);
  const [youtubeVideoURL, setYoutubeVideoURL] = useState('');

  const showYoutubeVideoHandler = (url: string) => {
    setYoutubeVideoURL(url);
    setShowYoutubeVideoModal(true);
  };

  const hideYoutubeVideoHandler = () => {
    setShowYoutubeVideoModal(false);
  };

  const openUrl = (url: string) => {
    window.open(url, '_blank');
  };

  const navigateToUrl = (path: string) => {
    history.push(path);
  };

  const navigateToSendingSettings = (item) =>
    navigateToUrl(
      `/email-accounts/${params.hashId || item.hashId}/sending-settings`,
    );

  return (
    <>
      <ScoreFactorDetailItem
        scoreFactorStatus={getScoreFactorStatusForSPF(
          emailAccountSetup?.spf?.score,
        )}
        title="SPF Set-up"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        onViewYoutubeVideo={() => showYoutubeVideoHandler(supportUrls.spfYtUrl)}
        youtubeIconTooltipText="Learn how to set SPF Records"
        description={getSPFDescription(emailAccountSetup?.spf?.score)}
        hasActionable={emailAccountSetup?.spf?.score === SpfScore.min}
        actionableText="Learn to set SPF"
        actionableHandler={() => openUrl(supportUrls.spfBlog)}
        showActionableList={showActionableList}
        actionableList={emailAccountSetup?.spf?.actionables}
      />

      <ScoreFactorDetailItem
        scoreFactorStatus={getScoreFactorStatusForCTD(
          emailAccountSetup?.customDomain?.score,
        )}
        title="Custom Domain Set-up"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        onViewYoutubeVideo={() => showYoutubeVideoHandler(supportUrls.ctdYtUrl)}
        youtubeIconTooltipText="Learn how to set Custom Tracking Domain"
        description={getCTDDescription(emailAccountSetup?.customDomain?.score)}
        hasActionable={
          emailAccountSetup?.customDomain?.score === CustomDomainScore.min
        }
        actionableText="Set Custom Domain"
        actionableHandler={() => navigateToUrl('/settings/custom-domain')}
        showActionableList={showActionableList}
        actionableList={emailAccountSetup?.customDomain?.actionables}
        showActionWithListOnly={true}
      />

      <ScoreFactorDetailItem
        scoreFactorStatus={getScoreFactorStatusForDSQ(
          emailAccountSetup?.dailySendingQuota?.score,
        )}
        title="Daily Sending Quota"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        hideYoutubeIcon
        description={getDSQDescription(
          emailAccountSetup?.dailySendingQuota?.score,
        )}
        hasActionable={
          emailAccountSetup?.dailySendingQuota?.score ===
            DailySendingQuotaScore.min ||
          emailAccountSetup?.dailySendingQuota?.score ===
            DailySendingQuotaScore.mid
        }
        actionableText="Set Quota"
        actionableHandler={navigateToSendingSettings}
        showActionableList={showActionableList}
        actionableList={emailAccountSetup?.dailySendingQuota?.actionables}
        showActionWithListOnly={true}
      />

      <ScoreFactorDetailItem
        scoreFactorStatus={getScoreFactorStatusForDMARC(
          emailAccountSetup?.dmarc?.score,
        )}
        title="DMARC Set-up"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        onViewYoutubeVideo={() =>
          showYoutubeVideoHandler(supportUrls.dmarcYtUrl)
        }
        youtubeIconTooltipText="Learn how to set DMARC"
        description={getDMARCDescription(emailAccountSetup?.dmarc?.score)}
        hasActionable={emailAccountSetup?.dmarc?.score === DmarcScore.min}
        actionableText="Learn to set DMARC"
        actionableHandler={() => openUrl(supportUrls.dmarcBlog)}
        showActionableList={showActionableList}
        actionableList={emailAccountSetup?.dmarc?.actionables}
      />

      <ScoreFactorDetailItem
        scoreFactorStatus={getScoreFactorStatusForEmailAge(
          emailAccountSetup?.emailAccountAge?.score,
        )}
        title="Email Age"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        onViewYoutubeVideo={() =>
          showYoutubeVideoHandler(supportUrls.emailAgeYtUrl)
        }
        youtubeIconTooltipText="Learn how to warm-up Email Age"
        description={getEmailAgeDescription(
          emailAccountSetup?.emailAccountAge?.score,
        )}
        hasActionable={
          emailAccountSetup?.emailAccountAge?.score === EmailAgeScore.min ||
          emailAccountSetup?.emailAccountAge?.score === EmailAgeScore.mid
        }
        actionableType="select"
        actionableText=""
        actionableHandler={({ emailAccountId, ageInMonths }) => {
          const id =
            emailAccountId !== null
              ? emailAccountId
              : emailAccountSetup?.emailAccountAge?.actionables[0].id;
          sendUpdateEmailAgeRequest({
            emailAccountId: id,
            ageInMonths,
          });
        }}
        showActionableList={showActionableList}
        actionableList={emailAccountSetup?.emailAccountAge?.actionables}
        showActionWithListOnly={showActionableList}
        dropdownSelectedKey={getEmailAgeDropdownSelectedKey(
          emailAccountSetup?.emailAccountAge?.score,
        )}
      />

      <ScoreFactorDetailItem
        scoreFactorStatus={getScoreFactorStatusForSendingInterval(
          emailAccountSetup?.interval?.score,
        )}
        title="The interval between two emails"
        isAllowedToViewYTVideoGuide={agencyConfig?.showYTVideoGuide}
        hideYoutubeIcon
        description={getSendingIntervalDescription(
          emailAccountSetup?.interval?.score,
        )}
        hasActionable={
          emailAccountSetup?.interval?.score === SendingIntervalScore.min ||
          emailAccountSetup?.interval?.score === SendingIntervalScore.mid
        }
        actionableText="Change Interval"
        actionableHandler={navigateToSendingSettings}
        showActionableList={showActionableList}
        actionableList={emailAccountSetup?.interval?.actionables}
        showActionWithListOnly={true}
      />

      <YoutubeVideoModal
        show={showYoutubeVideoModal}
        youtubeVideoUrl={youtubeVideoURL}
        onClose={hideYoutubeVideoHandler}
      />
    </>
  );
};

export default EmailAccountSetupFactors;
